
import React, { useState, useEffect } from 'react'
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
import {
  RefreshIcon,
  TrashIcon,
  PencilAltIcon,
  EyeIcon,
  MailIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import { ChevronDownIcon, FilterIcon } from "@heroicons/react/outline";
import { Disclosure, Transition } from "@headlessui/react";

import NavBar from 'layout/NavBar';
import Tabs from 'layout/Tabs';
import Error from 'layout/Error';
import Loader from 'layout/Loader';
import SkeletonTable from 'components/SkeletonTable';
import moment from "moment/moment";
import EmptyStates from 'components/EmptyStates';
import AlertModal from 'components/AlertModal';
import PaginationAll from 'components/pagination';
import { Item } from 'components/kanbanBoard/Item';

//Pagination Import
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-date-picker';

const pageTitle = "All Transaction";
const statusTextColors = {
    open: "text-black",
    pending: "text-purple-500",
    cancelled: "text-gray-500",
    resolved: "text-green-500",
  };

export default function ListWorkflow() {

  // for Paginantion
    const [apiTotalPage , setApiTotalpage] = useState(0);
    const [currentPage , setCurrentPage] = useState(0);
    // const [current , setCurrent] = useState(0);
    const [firstPage, setFirstPage] = useState();

    const [items , setItems] = useState();
    const [newitems , setNewitems] = useState();

    const [filter, setFilter ] = useState();
    const [catagories , setCatagories] = useState();
    const [companies , setCompanies] = useState();
    const [id , setId] = useState();
    const [name , setName] = useState();
    const [priority , setPriority] = useState();

    const [searchWorkflow, setSearchWorkflow]         = useState();
    const [searchCatagory, setSearchCatagory]         = useState();
    const [searchCompany, setSearchCompany]           = useState();
    const [searchId, setSearchId]                     = useState()
    const [prio , setPrio ] = useState();
    const [searchFrom, setSearchFrom] = useState();
    const [searchTo, setSearchTo] = useState();
    const [searchFormatedFrom, setSearchFormatedFrom] = useState()
    const [searchFormatedTo, setSearchFormatedTo] = useState()
    const [upDown, setUpDown] = useState(false)
    const [upDownUpdown, setUpDownUpdown] = useState(false)

    const [isdownload , setIsDownload] = useState(false);
    const [dataDownload , setDataDownload] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    // for total_records_per_page
    const [itemsPerPage, setItemsPerPage] = useState(10);

    let [searchParams, setSearchParams] = useSearchParams();
    let url;
    let export_file
    let recordPerPage;

  console.log(currentPage, "------------------------------------current_page----------------->")
    const [filterStatus, setFilterStatus] = useState(
        searchParams.get("status") ? searchParams.get("status") : ""
    );
 

    useEffect(()=>{
        apiHit();
      },[searchId,prio,searchCompany,searchCatagory,searchWorkflow,searchFrom,searchTo,currentPage,isdownload,upDown,itemsPerPage]);      
      const apiHit = async()=> {
        let id = searchId ? `id=${searchId}&` : '';
        let priorityy = prio ? `prio=${prio}&` : '';
        let companyy = searchCompany ? `company_name=${searchCompany}&` : '';
        let catagoryy = searchCatagory ? `category=${searchCatagory}&` : '';
        let workfloww = searchWorkflow ? `workflow=${searchWorkflow}&` : '';
        let fromm = searchFormatedFrom   ?  `from=${searchFormatedFrom === 'Invalid date'? '': searchFormatedFrom }&` : "";
        let too =  searchFormatedTo  ? `to=${searchFormatedTo === 'Invalid date' ? '' : searchFormatedTo}&` :  "";
        // let curr = searchId || prio || searchCompany || searchCatagory || searchWorkflow || searchFormatedFrom || searchFormatedTo ? setCurrentPage(1) : currentPage ;
        let sorting =   `sort=${upDown ? "desc" : "asc"}`;
        export_file = isdownload ? `export_file="xlsx" ` : "";
        recordPerPage = itemsPerPage>1 ? recordPerPage = `itemsPerPage=${itemsPerPage}&`:"";
        if((id=='')&&(priorityy=='')&&(companyy=='')&&(catagoryy=='')&&(workfloww=='')&&(fromm=='')&&(too=='')&&(sorting!='')){
         url =  `/workflow/amount/request?${export_file}${recordPerPage}${sorting}&page=${currentPage}`;
        }
        else if((id=='')&&((priorityy=='')||(companyy=='')||(catagoryy=='')||(workfloww=='')||(fromm=='')||(too=='')||(sorting!=''))){
          url = `/workflow/amount/request?${id}${priorityy}${companyy}${catagoryy}${workfloww}${fromm}${too}${export_file}${recordPerPage}${sorting}&page=${currentPage}`;
        }
        else if((id!='')&&((priorityy=='')||(companyy=='')||(catagoryy=='')||(workfloww=='')||(fromm=='')||(too=='')||(sorting!=''))){
          url = `/workflow/amount/request?${id}${priorityy}${companyy}${catagoryy}${workfloww}${fromm}${too}${export_file}${recordPerPage}${sorting}`;
        }
        else if((id!='')&&(!((priorityy=='')||(companyy=='')||(catagoryy=='')||(workfloww=='')||(fromm=='')||(too==''))||(sorting==''))){
          url = `/workflow/amount/request?${id}${priorityy}${companyy}${catagoryy}${workfloww}${fromm}${too}${export_file}${recordPerPage}${sorting}`;
        }
        await axios
        .get(url)
        .then(function (response) {
          
          try {
            if(!isdownload)
            {
              setItems(response.data.data.data);
              setNewitems(response.data.data.data)
              setFilter(response);
              setApiTotalpage(response.data.data.last_page);
              setFirstPage(response.data.data.current_page);
            }
            if(isdownload){
              const link = document.createElement('a');
              link.href = response.data.data;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setDataDownload(response.data.data);
              setIsDownload(false);
              setLoading(false);
              console.log(response.data.data,"link")
            }
            } catch (error) {
            console.log("FFFFFF",error);  
            setErrors(error.response.data);
            setLoading(false);
            }  
          
        })
        .catch(function (error) {
          console.log("ERROR",error);
        });
      }
      useEffect(()=>{
        workflowData();
      },[]);
       const workflowData = async()=> {
        await axios
        .get("/workflow/data")
        .then(function (response) {
          try {
            setCatagories(response?.data?.data?.categories)
            setCompanies(response?.data?.data?.companies)
            setId(response?.data?.data?.id)
            setName(response?.data?.data?.name)
            setPriority(response?.data?.data?.priority)
          } catch (error) {
          console.log("FFFFFF",error);  
          }
          
        })
        .catch(function (error) {
          console.log("ERROR",error);
       
        });
      }
      const handleSearchChange = (event , key) => {
        if (key === "workflow") {
          setSearchWorkflow(event.target.value);  
         }
         if(key === "catagory") {
          setSearchCatagory(event.target.value);
         }
         if(key === "company"){
          setSearchCompany(event.target.value);
         }
         if(key === "prio"){
          setPrio(event.target.value);
         }
         if(key === "id"){
          setSearchId(event.target.value);
         }
      }
      function refreshData(){
        window.location.reload(false);
        // setSearchCatagory("");
        // setSearchCompany("");
        // setSearchFormatedFrom("")
        // setSearchFormatedTo("")
        // setSearchId("")
        // setPrio("")
        // setSearchWorkflow("")
      }
      const handlePageChange = (e) => {
        // if(filter){

          // setCurrentPage(e.selected + 1);
          setCurrentPage(e)
        // }
        // loadData(pageNumber);
    };
      function selectDate(val){
        setSearchFrom(val)
        let formatedDate = moment(val).format('YYYY/MM/DD')
        setSearchFormatedFrom(formatedDate)
      }
      function selectDateTo(val){
        setSearchTo(val)
        let formatdate = moment(val).format('YYYY/MM/DD')
        setSearchFormatedTo(formatdate)
      }
      function dateReverseAgain(){
        setUpDown(upDown => !upDown)
      }
      function ascendingDate(){
        let acsen = newitems.sort((a, b) => new Date(...a.created_at.split('/').reverse()) - new Date(...b.created_at.split('/').reverse()));
        setItems(acsen)
        // console.log(acsen, "---------------------------------ascendingDate----------------------->")
      }
      function descendingDate(){
        let descending = newitems.sort((a, b) => new Date(...b.created_at.split('/').reverse()) - new Date(...a.created_at.split('/').reverse()));
        setItems(descending) 
        // console.log(descending, "-----------------------------------------------descendingdate-------------------------------------->")
      }
      function amountAscending(){
        let newArr= [...newitems].map((e, array, Arr) =>{
          return (
                 {...e,amount:  e.amount.replace(',','')}
          )
        })
        const numAscending = [...newArr].sort((a, b) => a.amount - b.amount);
        let localeStringArr= [...numAscending].map((e, array, Arr) =>{
          return (
                 {...e,amount:  JSON.parse( e.amount).toLocaleString()}
          )
        })
       
        setItems(localeStringArr);
        // console.log(newArr, "-----------------------------------------numAscending--------------------------->")
      }
      function amountDescending(){
        let newArray= [...newitems].map((e, array, Arr) =>{
          return (
                 {...e,amount:  e.amount.replace(',','')}
          )
        })
        const numDescending = [...newArray].sort((a, b) => b.amount - a.amount);
        let localeStringArr= [...numDescending].map((e, array, Arr) =>{
          return (
                 {...e,amount:  JSON.parse( e.amount).toLocaleString()}
          )
        })
        setItems(localeStringArr);
        // console.log(numDescending, "----------------------------------------numDescending--------------------------->")
      }
      const isClicked = () => {
        setIsDownload(true);
        setLoading(true);
        
        setTimeout(() => {
          // setLoading(false); // Stop the loader after export is complete
        }, dataDownload);
        // navigate(`/download${dataDownload}`)
      }


  return (
    <div className="min-h-[100vh] bg-gray-50 pb-1">
   <NavBar className="ml-5" pageTitle={pageTitle}>
    </NavBar>
      <div className="px-4 py-3 col-span-full lg:col-span-3">
        <Disclosure>
          <div>
            <button
              onClick={() => refreshData()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
            >
              <RefreshIcon
                className={
                  " mr-2 h-5 w-5"
                }
                aria-hidden="true"
              />
              Refresh
            </button>
            <Disclosure.Button className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">

              <FilterIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Filters
            </Disclosure.Button>

            <span className='float-right'>
              {loading && <span className="mr-5">Loading...</span> }
              {loading ? 
              <button 
                onClick={isClicked} 
                disabled={loading }
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-white bg-slate-600 focus:outline-none focus:ring-0 "
              >
                Export              
              </button> :
              <button 
                onClick={isClicked} 
                disabled={loading }
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 "
              >
                Export              
              </button>
              }
              {/* total_records_per_page */}
               <select
                    className="rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff] ml-4"
                    name=""
                    id=""
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </select>
            </span>  
          </div>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="text-gray-500">
              <div className="mt-3 px-3 py-3 rounded-lg bg-white shadow">
                <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
                  <div>
                    <div className="mt-2.5">

                      <input
                        type="text"
                        placeholder="Search by ID"
                        value={searchId}
                        onChange={(e) => handleSearchChange(e, "id")}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-2.5">
                      <DatePicker
                       type="date"
                       value={searchFrom}
                       onChange={selectDate}
                      //  format={"YYYY/MM/DD"}
                      //  clearIcon={cleatDateFrom}
                       className="block w-full rounded-md border-gray-300 py-1 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-2.5">
                      <DatePicker
                      value={searchTo}
                      onChange={selectDateTo}
                      // format={"YYYY/MM/DD"}
                      // clearIcon={cleatDateTo }
                      
                      className="block w-full rounded-md border-gray-300 py-1 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                        {/* <input
                        type="date"
                        name="name"
                        value={searchTo}
                        onChange={(e) => handleSearchChange(e, "to")}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        id="name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      /> */}
                    </div>
                  </div>
                  <div>
                    <div className="mt-2.5">
                      <select
                        className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={prio}
                        onChange={(e) => handleSearchChange(e , "prio")}
                      >
                        <option value="">Priority</option>
                        <option value={priority?.Urgent}>{priority?.Urgent}</option>
                        <option value={priority?.High}>{priority?.High}</option>
                        <option value={priority?.low}>{priority?.low}</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2.5">
                      <select
                        className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={searchCompany}
                        onChange={(e) => handleSearchChange(e , "company")}
                      >
                        <option value="">Company</option>
                        {
                          companies?.map((item, index) => {
                            return(
                              <option value={item} key={index}>{item}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2.5">
                      <select
                        className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={searchCatagory}
                        onChange={(e) => handleSearchChange(e , "catagory")}
                      >
                        <option value="">Catagory</option>
                        {
                          catagories?.map((item, index) => {
                            return(
                              <option value={item}>{item}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2.5">
                      <select
                        className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={searchWorkflow}
                        onChange={(e) => handleSearchChange(e , "workflow")}
                      >
                        <option value="">Workflow</option>
                        {
                          name?.map((item, index) => {
                            return(
                              <option value={item} key={index}>{item}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2.5"></div>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </Disclosure>
      </div>
      <div className="mt-0 overflow-x-auto">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <table className="min-w-full overflow-x-auto">
            <thead>
                {filterStatus != "save_as_draft" && (
                <tr className="border-t border-gray-200">
                    <th
                    className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                    >
                    <span className="whitespace-nowrap">ID</span>
                    </th>
                    <th
                    className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                    >
                    <span className="whitespace-nowrap">Name & Requester</span>
                    </th>
                    <th
                        className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                    >
                        <span className="whitespace-nowrap">Company</span>
                    </th>
                    <th
                    className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                    >
                    PAYMENT TO NAME
                    </th>
                    <th
                    className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                    >
                    PAYMENT METHOD
                    </th>
                    <th
                    className="... flex items-center whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                    >
                    AMOUNT
                    {/* <button 
                    className='rounded-sm bg-gray-200 ml-1 p-[2px]'
                    onClick={() => setUpDownUpdown(upDownUpdown => !upDownUpdown)}
                    >
                      {upDownUpdown ? 
                      <ChevronUpIcon 
                      className='text-black h-4 w-4 text-lg' 
                      // onClick={amountDescending}
                      />:
                      <ChevronDownIcon 
                      className='text-black h-4 w-4 text-lg' 
                      // onClick={amountAscending} 
                      />}
                    </button> */}
                    </th>
                    <th
                    className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                    >
                    PAYMENT FROM ACCOUNT 
                    </th>
                    <th
                    className="whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col"
                    >
                    PAYMENT TO ACCOUNT
                    </th>
                    <th
                    className="... flex items-center whitespace-nowrap overflow-hidden text-clip px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider h-full m-auto align-middle"
                    scope="col"
                    >
                    Created At
                    
                    <button 
                    className='rounded-sm bg-gray-200 ml-1 p-[2px]'
                    onClick={dateReverseAgain}
                    >
                      {upDown ? 
                      <ChevronUpIcon className='text-black h-4 w-4 text-lg' 
                      // onClick={descendingDate}
                      />:
                      <ChevronDownIcon 
                      className='text-black h-4 w-4 text-lg' 
                      // onClick={ascendingDate}
                      />}
                    </button>
                    </th>
                </tr>
                )}
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
                  { 
                  items ?
                    items?.map((e , index) => {
                      return(
                        <tr
                        className=""
                      >
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.workflow_id ? e?.workflow_id : 'N/A'}
                        </td> 
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          <div>
                          <a className='pointer-events-auto text-blue-700 underline' href={"https://grandizer.dev/all-workflow-requests/" + e?.workflow_request_id} >
                            {e?.workflow_name ? e?.workflow_name : 'N/A'}
                          </a>
                          </div>
                          <div>
                          {e?.requester ? e?.requester : 'N/A'}
                          </div>
                        </td>  
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.company_name ? e?.company_name : 'N/A'}
                        </td>  
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_to_name  ? e?.payment_to_name  : '-'}
                        </td>  
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_method ? e?.payment_method : '-'}
                        </td>    
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.amount ? e?.amount : '0'}
                        </td> 
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_from_account ? e?.payment_from_account : '- '}
                        </td>  
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.payment_to_account ? e?.payment_to_account : '-'}
                        </td> 
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {e?.created_at  ? e?.created_at  : 'N/A'}
                        </td>
                      </tr> 
                      )
                    }
                    ) : 
                    <tr>
                      <td className='text-gray-500 my-40 text-center' colSpan="9">Loading</td>
                    </tr>
                    }
            </tbody>
          </table>
        </div>
      </div>
      <div className="my-4">
        {/* <ReactPaginate 
          previousLabel={(firstPage === 1 ? "" : "<< " )+ "Previous"}
          nextLabel={"Next" + ( firstPage === apiTotalPage ? "" : ">>")}
          pageCount={apiTotalPage}
          onPageChange={handlePageChange}
          containerClassName={"flex justify-center "}
          pageClassName={"mr-2 ml-2 border-gray-300 border-t-2 pl-2 pr-2"}
          pageLinkClassName={" "}
          previousClassName="pl-2 pr-2"
          previousLinkClassName=""
          nextClassName=" pl-2 pr-2"
          nextLinkClassName=""
          activeClassName={"active border-t-2 border-blue-500"}
        /> */}
         <PaginationAll
              currentPage={firstPage}
              totalPages={apiTotalPage}
              onPageChange={handlePageChange}
          />
      </div>
    </div>
    
  )
}