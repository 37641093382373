import { useState, useEffect } from "react";
import {
  ExclamationCircleIcon,
  XIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { Country } from "country-state-city";
import Select from "react-select";

export default function UpdateLocationArrayField(props) {
  const {
    label,
    input,
    meta,
    columns,
    required = false,
    companyLocationData,
  } = props;
  const [dataRows, setDataRows] = useState(companyLocationData);
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.name,
    ...country,
  }));

  function addDataRow() {
    let newRowData = { location_name: "", country: "" };
    setDataRows([...dataRows, newRowData]);
  }

  const handleChange = (e, idx) => {
    const clone = [...dataRows];
    let obj = clone[idx];
    obj.location_name = e.target.value;
    clone[idx] = obj;
    setDataRows([...clone]);
    input.onChange([...dataRows]);
  };

  const handleCountryChange = (value, idx) => {
    const clone = [...dataRows];
    let obj = clone[idx];
    obj.country = value.value;
    clone[idx] = obj;
    setDataRows([...clone]);
    input.onChange([...dataRows]);
  };

  function removeDataRow(dataRowIndex) {
  
    let newDataRows = dataRows.slice();
    newDataRows.splice(dataRowIndex, 1);
    setDataRows(newDataRows);
    input.onChange(newDataRows);
  }

  return (
    <div className="sm:col-span-6">
      <div className="mt-1 relative">
        {dataRows?.map((dataRow, dataRowIndex) => (
          <div className="relative pr-10" key={dataRowIndex}>
            <div className={"mb-2 grid gap-4 grid-cols-1 sm:grid-cols-2"}>
              <div>
                <label
                  htmlFor={columns.location_name}
                  className="block text-sm font-medium text-gray-700"
                >
                  Location Name
                  {required && <span className="text-red-500">*</span>}
                </label>
                <input
                  type="text"
                  onChange={(e) => handleChange(e, dataRowIndex)}
                  placeholder={columns.location_name}
                  value={dataRow?.location_name ?? dataRow?.name}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                />
              </div>
              <div>
                <label
                  htmlFor={input.name}
                  className="block text-sm font-medium text-gray-700"
                >
                  Country{required && <span className="text-red-500">*</span>}
                </label>
                <Select
                  label="Country"
                  onChange={(value) => handleCountryChange(value, dataRowIndex)}
                  defaultInputValue={dataRow.country}
                  defaultValue={dataRow.country}
                  options={updatedCountries}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </div>
            <button
              onClick={() => removeDataRow(dataRowIndex)}
              type="button"
              className="my-5 absolute right-0 top-1 inline-flex items-center p-1 border border-transparent rounded-full text-white"
            >
            <TrashIcon className="h-6 w-6 text-red-400" aria-hidden="true"/>
            </button>
          </div>
        ))}
        <div className="flex flex-nowrap justify-center flex-col items-center">
          <div>
            <p className="mt-1 text-sm text-gray-500 ">Add New Location</p>
          </div>
          <button
            onClick={() => addDataRow()}
            type="button"
            className="text-center relative inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75 justify-center"
          >
            <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}
