
import React , {useState , useEffect} from 'react'
import { Switch } from '@headlessui/react'
import { Field } from 'react-final-form';

export default function WorkingHoursToggleUpdate(props) {

    const { label , input } = props


    console.log("check" , input)
    const [enabled, setEnabled] = useState();
    const [fieldEnabled, setFielsEnabled] = useState(false);

    const [dataRows, setDataRows] = useState(
      input.value ? input.value : { open: "", close: "" }
    );

    useEffect(() => {

// check if all properties have values
let allValuesFilled = true;
for (let key in input.value) {
  if (input.value[key] === '' || input.value[key] === null || input.value[key] === undefined) {
    allValuesFilled = false;
    break;
  }
}
if (allValuesFilled) {
  setEnabled(input.value);
  setFielsEnabled(input.value);
}
        
      dataRows.open = input.value?.open
      setOpen(input.value?.open);
      dataRows.close = input.value?.close
      setClose(input.value?.close);
      setDataRows(dataRows); 
    }, [input.value])
    



    const [open, setOpen] = useState();
    const [close, setClose] = useState();



    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }

      function handleChange(value) {
        setEnabled(value);
        setFielsEnabled(value);
        // input.onChange(value);
      }

      function handleOpenChange(event) {
        const value = event.target.value;
        dataRows.open = value;
        setOpen(value);
        setDataRows(dataRows);
        if (value == "" && dataRows.open == "") {
          input.onChange(false);
        } else {
          input.onChange(dataRows);
        }
      }

      function handleCloseChange(event) {
        const value = event.target.value;
        dataRows.close = value;
        setClose(value);
        setDataRows(dataRows);
        if (value == "" && dataRows.close == "") {
          input.onChange(false);
        } else {
          input.onChange(dataRows);
        }
      }


   
   


    
  return (
   
    <div class="grid grid-cols-3 col-span-12">
    <div class="h-16">{label}</div>
    <div class="h-16">
    <Switch
            checked={enabled}
            onChange={handleChange}
            className={classNames(
              enabled ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
          >
            <span
              aria-hidden="true"
              
              className={classNames(
                enabled ? "translate-x-8" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            >
                
                 </span>
          </Switch>
    </div>
    <div class="h-16">

        {fieldEnabled ? 
        
        <div>
         
            <select name="open" id="" className='mr-2'  {...input}   onChange={(event) => handleOpenChange(event)}
                value={open}>
                <option value="">Open</option>
                <option value="24 hours">24 hours</option>
                <option value="12:00 AM">12:00 AM</option>
                <option value="12:30 AM">12:30 AM</option>
                <option value="01:00 AM">01:00 AM</option>
                <option value="01:30 AM">01:30 AM</option>
                <option value="02:00 AM">02:00 AM</option>
                <option value="02:30 AM">02:30 AM</option>
                <option value="03:00 AM">03:00 AM</option>
                <option value="03:30 AM">03:30 AM</option>
                <option value="04:00 AM">04:00 AM</option>
                <option value="04:30 AM">04:30 AM</option>
                <option value="05:00 AM">05:00 AM</option>
                <option value="05:30 AM">05:30 AM</option>
                <option value="06:00 AM">06:00 AM</option>
                <option value="06:30 AM">06:30 AM</option>
                <option value="07:00 AM">07:00 AM</option>
                <option value="07:30 AM">07:30 AM</option>
                <option value="08:00 AM">08:00 AM</option>
                <option value="08:30 AM">08:30 AM</option>
                <option value="09:00 AM">09:00 AM</option>
                <option value="09:30 AM">09:30 AM</option>
                <option value="10:00 AM">10:00 AM</option>
                <option value="10:30 AM">10:30 AM</option>
                <option value="11:00 AM">11:00 AM</option>
                <option value="11:30 AM">11:30 AM</option>
                <option value="12:00 PM">12:00 PM</option>
                <option value="12:30 PM">12:30 PM</option>
                <option value="01:00 PM">01:00 PM</option>
                <option value="01:30 PM">01:30 PM</option>
                <option value="02:00 PM">02:00 PM</option>
                <option value="02:30 PM">02:30 PM</option>
                <option value="03:00 PM">03:00 PM</option>
                <option value="03:30 PM">03:30 PM</option>
                <option value="04:00 PM">04:00 PM</option>
                <option value="04:30 PM">04:30 PM</option>
                <option value="05:00 PM">05:00 PM</option>
                <option value="05:30 PM">05:30 PM</option>
            </select>
             -
            <select name="close" id="" className='ml-2' {...input}   onChange={(event) => handleCloseChange(event)}
                value={close}>
                <option value="">Close</option>
                <option value="24 hours">24 hours</option>
                <option value="09:30 AM">09:30 AM</option>
                <option value="10:00 AM">10:00 AM</option>
                <option value="10:30 AM">10:30 AM</option>
                <option value="11:00 AM">11:00 AM</option>
                <option value="11:30 AM">11:30 AM</option>
                <option value="12:00 PM">12:00 PM</option>
                <option value="12:30 PM">12:30 PM</option>
                <option value="01:00 PM">01:00 PM</option>
                <option value="01:30 PM">01:30 PM</option>
                <option value="02:00 PM">02:00 PM</option>
                <option value="02:30 PM">02:30 PM</option>
                <option value="03:00 PM">03:00 PM</option>
                <option value="03:30 PM">03:30 PM</option>
                <option value="04:00 PM">04:00 PM</option>
                <option value="04:30 PM">04:30 PM</option>
                <option value="05:00 PM">05:00 PM</option>
                <option value="05:30 PM">05:30 PM</option>
                <option value="06:00 PM">06:00 PM</option>
                <option value="06:30 PM">06:30 PM</option>
                <option value="07:00 PM">07:00 PM</option>
                <option value="07:30 PM">07:30 PM</option>
                <option value="08:00 PM">08:00 PM</option>
                <option value="08:30 PM">08:30 PM</option>
                <option value="09:00 PM">09:00 PM</option>
                <option value="09:30 PM">09:30 PM</option>
                <option value="10:00 PM">10:00 PM</option>
                <option value="10:30 PM">10:30 PM</option>
                <option value="11:00 PM">11:00 PM</option>
                <option value="11:30 PM">11:30 PM</option>
                <option value="Midnight">Midnight</option>

            </select>
        </div>

        : "" }
    </div>
  </div>
  
   
  )
}
