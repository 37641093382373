import { useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'

export default function NumberField(props) {
    const { label, helpText, input, meta, placeholder=null, col = 3, required = false, percentage = false, min = '', max = '', value=null} = props
    const [inputValue, setInputValue] = useState(input.value)

    function handleChange(event) {
        let value = event.target.value
        if(percentage) {
            if(parseInt(value) < 0) {
                value = 0
            }
            if(parseInt(value) > 100) {
                value = 100
            }
        }
        if(max) {
            if(parseInt(value) > parseInt(max)) {
                value = max
            }
        }
        if(min) {
            if(parseInt(value) < parseInt(min)) {
                value = min
            }
        }
        setInputValue(value)
        input.onChange(value)
    }

    return (
        <div className={'col-span-12 sm:col-span-'+col}>
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    value={inputValue}
                    onChange={(event) => handleChange(event)}
                    type="number"
                    min={percentage ? '0' : min}
                    max={percentage ? '100' : max}
                    placeholder={placeholder}
                    className={ (meta.error && meta.touched) ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300'}
                />
                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>
            {helpText && <p className="mt-2 text-sm text-right text-gray-500">{helpText}</p>}
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}