import { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import Select from "react-select";
import { Country, City } from "country-state-city"

export default function AddressField(props) {
    const { label, input, meta, required = false } = props
    const countries = Country.getAllCountries();
    const [selectedCountry, setSelectedCountry] = useState(input.value.country)
    const [selectedCity, setSelectedCity] = useState(input.value.city)
    const [address, setAddress] = useState(input.value.address)

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country
      }));

    function handleChange(value) {
        setSelectedCountry(value)
        setSelectedCity(null)
        input.onChange({ country: value.name, city: selectedCity ? selectedCity.name : null, address: address })
    }

    function handleCityChange(value) {
        setSelectedCity(value)
        input.onChange({ country: selectedCountry.name, city: value.name, address: address })
    }

    function handleAddressChange(event) {
        setAddress(event.target.value)
        input.onChange({ country: selectedCountry.name, city: selectedCity ? selectedCity.name : null, address: event.target.value })
    }
    
    useEffect(() => {
        if(input.value) {
            const selectedIndex = countries.findIndex((country) => country.name === input.value.country)
            if(selectedIndex >= 0) {
                setSelectedCountry(updatedCountries[selectedIndex])

                const cities = City.getCitiesOfCountry(updatedCountries[selectedIndex].isoCode).map((city) => ({ label: city.name, value: city.id, ...city }))
                const selectedCityIndex = cities.findIndex((city) => city.name === input.value.city)
             
                if(selectedCityIndex >= 0) {
                    setSelectedCity(cities[selectedCityIndex])
                }
            }
            
            
        } else {
            const selectedIndex = countries.findIndex((country) => country.name === 'Saudi Arabia')
            if(selectedIndex >= 0) {
                setSelectedCountry(updatedCountries[selectedIndex])
                input.onChange({ country: updatedCountries[selectedIndex].name, city: selectedCity ? selectedCity.name : null, address: address })
            }
        }
        
    }, [])

    const updatedCities = (countryId) => { 
        return City.getCitiesOfCountry(countryId).map((city) => ({ label: city.name, value: city.id, ...city }));
    }

    return (
        <div className="sm:col-span-6">
            <div className="mt-5 mb-2 border-t border-gray-200 pt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{label} {required && <span className="text-red-500">*</span>}</h3>
                <p className="mt-1 text-sm text-gray-500"></p>
            </div>
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                    <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                        Country
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <Select
                            label="country"
                            onChange={(value) => handleChange(value)}
                            options={updatedCountries}
                            value={selectedCountry}
                        />
                        {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                    </div>
                    {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
                </div>
                <div className="sm:col-span-2">
                    <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                        City
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <Select
                            label="city"
                            onChange={(value) => handleCityChange(value)}
                            options={updatedCities(selectedCountry ? selectedCountry.isoCode : null)}
                            value={selectedCity}
                        />
                        {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                    </div>
                    {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
                </div>
                <div className="sm:col-span-2">
                    <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                        Address
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            value={address}
                            onChange={(event) => handleAddressChange(event)}
                            type="text"
                            className={ (meta.error && meta.touched) ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'}
                        />
                        {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                    </div>
                    {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
                </div>
            </div>
        </div>
    )
}