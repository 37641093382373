// import React, { useState, useEffect } from 'react'
// import { useNavigate, Link, useSearchParams } from "react-router-dom";
// import axios from 'axios';
// import {
//   TrashIcon,
//   PencilAltIcon,
//   EyeIcon,
// } from "@heroicons/react/solid";
// import NavBar from 'layout/NavBar';
// import Tabs from 'layout/Tabs';
// import Error from 'layout/Error';
// import Loader from 'layout/Loader';
// import SkeletonTable from 'components/SkeletonTable';
// import moment from "moment/moment";
// import EmptyStates from 'components/EmptyStates';
// import AlertModal from 'components/AlertModal';
// import PaginationAll from 'components/pagination';

// // Static variables
// const pageTitle = "Employees";

// const statusTextColors = {
//   open: "text-black",
//   pending: "text-purple-500",
//   cancelled: "text-gray-500",
//   resolved: "text-green-500",
// };


// // Axios
// axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
// axios.defaults.withCredentials = true;

// export default function ListEmployee(props) {

//   let navigate = useNavigate();

//   const { user } = props;
//   let [searchParams, setSearchParams] = useSearchParams();
//   const [filterStatus, setFilterStatus] = useState(
//     searchParams.get("status") ? searchParams.get("status") : ""
//   );
//   const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [isDataLoading, setIsDataLoading] = useState(false);
//   const [filterCount, setFilterCount] = useState(0);
//   const [items, setItems] = useState([]);
//   const [saveAsDraftItems, setSaveAsDraftItems] = useState([]);

//   const [paginationMeta, setPaginationMeta] = useState([]);
//   const [tabs, setTabs] = useState([
//     { name: "Employees", color: "black", status: "", count: 0, current: true },
//     {
//       name: "Saved as draft",
//       color: "bg-pink-500",
//       status: "save_as_draft",
//       count: 0,
//       current: false,
//     },
//   ]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [currentPageSaveAsDraft, setCurrentPageSaveAsDraft] = useState(1);

//   const [searchQuery, setSearchQuery] = useState('');
//   // const itemsPerPage = 5;
//   const [itemsPerPage, setItemsPerPage] = useState(50)
//   const [addCompanyFilter, setAddCompanyFilter] = useState(null);

//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
  
//   const startIndexSaveAsDraft = (currentPageSaveAsDraft - 1) * itemsPerPage;
//   const endIndexSaveAsDraft = startIndexSaveAsDraft + itemsPerPage;

//   const displayedData = items?.slice(startIndex, endIndex);
//   const displayeSaveAsData = saveAsDraftItems?.slice(startIndexSaveAsDraft, endIndexSaveAsDraft);

//   const [text, setText] = useState("");

//   // Set page title
//   useEffect(() => {
//     document.title = pageTitle;
//   }, []);

//   // Order By
//   let curretnOrderByValue = {
//     key: "created_at",
//     order: "desc",
//   };
//   if (searchParams.get("order_by")) {
//     if (
//       searchParams.get("order_by").key !== "created_at" &&
//       searchParams.get("order_by").order !== "desc"
//     ) {
//       const currentOrderByArray = searchParams.get("order_by").split("-");
//       curretnOrderByValue = {
//         key: currentOrderByArray[0],
//         order: currentOrderByArray[1],
//       };
//     }
//   }
//   let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue);

//   const loadData = (params = {}) => {
//     setIsDataLoading(true);

//     if (params === {}) {
//       //params['status'] = ''
//     }
//     setSearchParams(params);
//     axios
//       .get("/employees/index", {
//         params: params,
//       })
//       .then(function (response) {
//         // handle success
//         setIsLoaded(true);
//         setItems(response.data.data);
//         setTimeout(function () {
//           setIsDataLoading(false);
//         }, 300);
//       })
//       .catch(function (error) {
//         // handle error
//         setError(error);
//       });
//   };
  
//   const loadSaveAsDraftData = (params = {}) => {
//     setIsDataLoading(true);

//     if (params === {}) {
//       //params['status'] = ''
//     }
//     setSearchParams(params);
//     axios
//       .get("/draft/get/employee")
//       .then(function (response) {
//         // handle success
//         setIsLoaded(true);
//         setSaveAsDraftItems(response.data.draft);
//         setTimeout(function () {
//           setIsDataLoading(false);
//         }, 300);
//       })
//       .catch(function (error) {
//         // handle error
//         setError(error);
//       });
//   };
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
// };
// const handlePageChangeSaveAsDraft = (pageNumber) => {
//   setCurrentPageSaveAsDraft(pageNumber);
// };
//   function refreshData() {
//     let params = {};
//     searchParams.forEach((key, value) => {
//       params[value] = key;
//       setFilterCount((filterCount) => filterCount + 1);
//     });

//     loadData(params);
//     loadSaveAsDraftData(params);
//   }

//   useEffect(() => {
//     refreshData();
//   }, []);

//   function reloadData(key, value) {
//     let params = {};
//     searchParams.forEach((key, value) => {
//       params[value] = key;
//     });
//     params[key] = value;

//     // Load tab
//     // if (key != "page") {
//     //   params["page"] = 1;
//     // }
//     if (value == "save_as_draft") {
//       loadSaveAsDraftData(params);
//     } else {
//       loadData(params);
//     }
//   }

//   // Orders
//   function orderBy(key) {
//     if (currentOrderBy.order === "desc") {
//       reloadData("order_by", key + "-asc");
//       setCurrentOrderBy({
//         key: key,
//         order: "asc",
//       });
//     } else {
//       reloadData("order_by", key + "-desc");
//       setCurrentOrderBy({
//         key: key,
//         order: "desc",
//       });
//     }
//   }

//   // Filters
//   const [filterCompanyId, setFilterCompanyId] = useState(
//     searchParams.get("company_id") ? searchParams.get("company_id") : ""
//   );
//   function addFilterSwitch(key, value) {
//     setFilterStatus(value);
//     reloadData(key, value);
//   }
//   function clearFilter() {
//     let params = {};
//     setFilterCompanyId("");
//     setFilterCount(0);
//     loadData(params);
//   }

//   function addFilter(e) {
//     e.preventDefault();
//     setAddCompanyFilter(e.target.value);
//     setIsDataLoading(true);

//     const formData = new FormData();

//     formData.append("name", e.target.value);
//     axios({
//       method: "get",
//       url: "/employees/index",
//       data: formData,
//       header: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//       .then(function (response) {
//         // handle success
//         setIsLoaded(true);
//         setItems(response.data.data);
//         setTimeout(function () {
//           setIsDataLoading(false);
//         }, 300);
//       })
//       .catch(function (error) {
//         // handle error
//         setError(error);
//       });
//   }
//   // /Filters

//   // Methods
//   const [deletingItem, setDeletingItem] = useState({ name: null });

//   function deleteItem(item) {
//     setDeletingItem(item);
//     setDeleteItemModalOpen(true);
//   }

//   function confirmDeleteItem() {
//     if (filterStatus == "save_as_draft") {
//       axios
//         .get("draft/delete/" + deletingItem.id, {
//           _method: "delete",
//         })
//         .then(function (response) {
//           setDeleteItemModalOpen(false);
//           refreshData();
//           setIsLoaded(true);
//           setTimeout(function () {
//             setIsDataLoading(false);
//           }, 300);
//           setDeletingItem({ name: null });
//         })
//         .catch(function (error) {
//           setDeleteItemModalOpen(false);
//           refreshData();
//           setIsLoaded(true);
//           setDeletingItem({ name: null });
//         });
//     } else {
//       axios
//         .get("delete/company/" + deletingItem.id, {
//           _method: "delete",
//         })
//         .then(function (response) {
//           setDeleteItemModalOpen(false);
//           refreshData();
//           setIsLoaded(true);
//           setTimeout(function () {
//             setIsDataLoading(false);
//           }, 300);
//           setDeletingItem({ name: null });
//         })
//         .catch(function (error) {
//           setDeleteItemModalOpen(false);
//           refreshData();
//           setIsLoaded(true);
//           setDeletingItem({ name: null });
//         });
//     }
//   }

//   const newArray = displayedData.map((item) => {
//     const words = item?.name?.split(' ');
//     const firstTwoWords = words.slice(0, 2); // slice the array to include only the first two elements
//     const firstLetters = firstTwoWords.map((word) => {
//       return word.charAt(0); // return the first character of each word
//     });
//     return firstLetters.join(''); // join the first letters to form a new string
//   });
  


 
  
//   // /Methods

//   function updateItem(item) {
//     navigate(`/employee/view/${item.id}`);
//   }

//   function editItem(item) {
//     if(item.status == "draft"){
      
//       navigate(`/company/draft/edit/${item.id}`);

//     }else{
//     navigate(`/company/edit/${item.id}`);
//     }
//   }

//   if (error) {
//     return <Error error={error} />;
//   }

//   if (!isLoaded) {
//     return <Loader fullscreen={false} />;
//   }

//   function classNames(...classes) {
//     return classes.filter(Boolean).join(" ");
//   }
//   return (
//     <div className="overflow-x-hidden min-h-[100vh] bg-gray-50 pb-1 relative">
//       <NavBar pageTitle={pageTitle}>
//         <Link
//           to="/employees/create"
//           type="button"
//           className="relative inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
//         >
//           Add New Employee
//         </Link>
//       </NavBar>

//       <div>
//         <div className="px-4 py-3 col-span-full lg:col-span-3">
//         <div className="col-span-12 lg:col-span-3">
//             <div className="mt-1 relative rounded-md">
//               <form className="pr-3 pt-3 pb-3">
//                 <input
//                   type="text"
//                   name="name"
//                   placeholder="Search by company name..."
//                   value={addCompanyFilter}
//                   onChange={(event) => addFilter(event)}
//                   onKeyPress={(e) => {
//                     e.key === "Enter" && e.preventDefault();
//                   }}
//                   className="p-2 w-full lg:w-2/5 outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded"
//                 />
//                 <div className='float-right'>
//                  <select className='rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]' name="" id="" value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                
//                   <option value="50">50</option>
//                   <option value="100">100</option>
//                   <option value="150">150</option>
//                   <option value="200">200</option>
//                  </select>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//  {/* Tabs */}
//  <Tabs
//           tabs={tabs}
//           statusTextColors={statusTextColors}
//           searchParams={searchParams}
//           addFilter={addFilterSwitch}
//         />

//          {/* Data Table */}
//          <div className="mt-0 overflow-x-auto">
//           <div className="align-middle inline-block min-w-full border-b border-gray-200">
//             {isDataLoading && <SkeletonTable />}
//             {!isDataLoading && (
//               <table className="min-w-full table-auto">
//                 <thead>
//                   {filterStatus != "save_as_draft" && (
//                     <tr className="border-t border-gray-200">
//                       <th
//                         className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         scope="col"
//                       >
//                         Action
//                       </th>
//                       <th
//                         className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         scope="col"
//                       >
//                         <span className="whitespace-nowrap">Name</span>
//                       </th>
//                       {filterStatus != "save_as_draft" && (
//                         <th
//                           className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                           scope="col"
//                         >
//                           <span className="whitespace-nowrap">EMAIL</span>
//                         </th>
//                       )}
//                       {filterStatus === "save_as_draft" && (
//                         <th
//                           className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                           scope="col"
//                         >
//                           <span className="whitespace-nowrap">Action</span>
//                         </th>
//                       )}
//                       {filterStatus === "save_as_draft" && (
//                         <th
//                           className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                           scope="col"
//                         >
//                           <span className="whitespace-nowrap">Created At</span>
//                         </th>
//                       )}
//                       {filterStatus != "save_as_draft" && (
//                         <th
//                           scope="col"
//                           className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         >
//                           {/* <button
//                           onClick={() => orderBy("created_at")}
//                           className="group inline-flex uppercase whitespace-nowrap"
//                         > */}
//                           STATUS
//                           {/* {currentOrderBy.key === "created_at" ? (
//                             <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
//                               {currentOrderBy.order === "desc" ? (
//                                 <ChevronDownIcon
//                                   className="h-4 w-4"
//                                   aria-hidden="true"
//                                 />
//                               ) : (
//                                 <ChevronUpIcon
//                                   className="h-4 w-4"
//                                   aria-hidden="true"
//                                 />
//                               )}
//                             </span>
//                           ) : (
//                             ""
//                           )} */}
//                           {/* </button> */}
//                         </th>
//                       )}

//                       <th
//                         className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         scope="col"
//                       >
//                         COMPANY
//                       </th>

//                       {/* <th
//                         className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         scope="col"
//                       >
//                         DEPARTMENT
//                       </th> */}
//                     </tr>
//                   )}
//                   {filterStatus == "save_as_draft" && (
//                     <tr className="border-t border-gray-200">
//                       <th
//                         className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         scope="col"
//                       >
//                         Action
//                       </th>
//                       <th
//                         className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         scope="col"
//                       >
//                         Name
//                       </th>
//                       <th
//                         className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                         scope="col"
//                       >
//                         <span className="whitespace-nowrap">Created At</span>
//                       </th>
//                     </tr>
//                   )}
//                 </thead>
//                 <tbody className="bg-white divide-y divide-gray-100">
//                   {filterStatus != "save_as_draft" &&
//                     displayedData.map((item , index) => (
//                       <tr
//                         className=""
//                         key={item.id}
//                       >
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
//                         <button
//                             // to="/employee/view/:employeeId"
//                             onClick={() => updateItem(item)}
//                             className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
//                           >
//                             <EyeIcon
//                               className="h-4 w-4 text-blue-400"
//                               aria-hidden="true"
//                             />
//                           </button>
//                           <button
//                             onClick={() => editItem(item)}
//                             className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
//                           >
//                             <PencilAltIcon
//                               className="h-4 w-4 text-green-400"
//                               aria-hidden="true"
//                             />
//                           </button>
//                           { item.id != 1 && (
//                           <button
//                             // onClick={() => deleteItem(item)}
//                             className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
//                           >
//                             <TrashIcon
//                               className="h-4 w-4 text-red-400"
//                               aria-hidden="true"
//                             />
//                           </button>
//                           )}
//                         </td>
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
//                          <span className='rounded-full bg-blue-50 border-1 border-gray-200 p-2 text-xs mr-2 text-blue-400'>{newArray[index]}</span>  {item?.name}
//                         </td>
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
//                           {item.email}
//                         </td>
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
//                           {item.status}
//                         </td>
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
//                           {item.name}
//                         </td>
//                         {/* <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
//                           {item.name}
//                         </td> */}
//                       </tr>
//                     ))}
//                   {filterStatus == "save_as_draft" &&
//                     displayeSaveAsData.map((item) => (
//                       <tr
//                         className="odd:bg-white even:bg-slate-50"
//                         key={item.id}
//                       >
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
//                           <button
//                             onClick={() => deleteItem(item)}
//                             className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
//                           >
//                             <TrashIcon
//                               className="h-4 w-4 text-red-400"
//                               aria-hidden="true"
//                             />
//                           </button>
//                           <button
//                             onClick={() => editItem(item)}
//                             className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
//                           >
//                             <PencilAltIcon
//                               className="h-4 w-4 text-green-400"
//                               aria-hidden="true"
//                             />
//                           </button>
//                           {/* <button
//                             onClick={() => updateItem(item)}
//                             className="inline-flex items-center px-2.5 py-1.5 text-xs font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
//                           >
//                             <EyeIcon
//                               className="h-4 w-4 text-blue-400"
//                               aria-hidden="true"
//                             />
//                           </button> */}
//                         </td>
//                         {/* <td className="px-6 py-3 whitespace-nowrap text-sm font-semibold text-gray-900">
//                         <div className="">
//                           <div>
//                             <Link
//                               to={`/offices/${item.id}/edit`}
//                               className="truncate text-blue-700 "
//                             >
//                               <span>
//                                 <span className="underline font-bold">
//                                   {item.name}
//                                 </span>
//                               </span>
//                             </Link>
//                           </div>

//                           {item.requester ? (
//                             <div className="flex items-center mb-1">
//                               <div className="flex flex-shrink-0 mr-1">
//                                 <img
//                                   key={"requester_" + item.requester.id}
//                                   className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
//                                   src={item.requester.avatar_url}
//                                   alt={item.requester.name}
//                                 />
//                               </div>
//                               <span className="flex-shrink-0 text-xs leading-5 font-semibold">
//                                 {item.requester.name}{" "}
//                                 {item.requester.company.name && (
//                                   <span>({item.requester.company.name})</span>
//                                 )}
//                               </span>
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       </td> */}

//                         {/* {user.managed_companies.length > 1 &&
//                         !filterCompanyId && (
//                           <td className="whitespace-nowrap px-6 py-3 w-full text-sm font-semibold text-gray-900">
//                             {item.company.name}
//                           </td>
//                         )} */}
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
//                           {item.name == null? "-":item.name}
//                         </td>
//                         <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
//                           {moment(item.created_at).format("DD/MM/YYYY")}
//                         </td>
//                       </tr>
//                     ))}
//                 </tbody>
//               </table>
//             )}
//           </div>
//         </div>
//         {/* /Data Table */}

//         {/* Empty state */}
//         {!items.length && isLoaded ? (
//           <EmptyStates createPath="/employee/create" />
//         ) : (
//           ""
//         )}


// <AlertModal
//         title="Delete Company"
//         note={`Are you sure you want to delete "${deletingItem.name}" Employee.`}
//         confirm={confirmDeleteItem}
//         close={() => setDeleteItemModalOpen(false)}
//         open={deleteItemModalOpen}
//       />

// {filterStatus != "save_as_draft" &&(
//           <PaginationAll
//               currentPage={currentPage}
//               totalPages={Math.ceil(items?.length / itemsPerPage)}
//               onPageChange={handlePageChange}
//           />
//       )
//       }

// {filterStatus == "save_as_draft" &&(
//           <PaginationAll
//               currentPage={currentPageSaveAsDraft}
//               totalPages={Math.ceil(saveAsDraftItems?.length / itemsPerPage)}
//               onPageChange={handlePageChangeSaveAsDraft}
//           />
//       )
//       }

//       </div>
      
//       )
// }









import React, { useState, useEffect } from 'react'
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
import {
  RefreshIcon,
  TrashIcon,
  PencilAltIcon,
  EyeIcon,
  MailIcon,
} from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";
import { Disclosure, Transition } from "@headlessui/react";

import NavBar from 'layout/NavBar';
import Tabs from 'layout/Tabs';
import Error from 'layout/Error';
import Loader from 'layout/Loader';
import SkeletonTable from 'components/SkeletonTable';
import moment from "moment/moment";
import EmptyStates from 'components/EmptyStates';
import AlertModal from 'components/AlertModal';
import PaginationAll from 'components/pagination';

// Static variables
const pageTitle = "Employees";

const statusTextColors = {
  open: "text-black",
  pending: "text-purple-500",
  cancelled: "text-gray-500",
  resolved: "text-green-500",
};


// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

export default function ListEmployee(props) {

  let navigate = useNavigate();

  const { user } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const [filterStatus, setFilterStatus] = useState(
    searchParams.get("status") ? searchParams.get("status") : ""
  );
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [items, setItems] = useState([]);
  const [saveAsDraftItems, setSaveAsDraftItems] = useState([]);
  const [statusEmployeeFilter, setStatusEmployeeFilter] = useState(null);


  const [paginationMeta, setPaginationMeta] = useState([]);
  const [tabs, setTabs] = useState([
    { name: "Employees", color: "black", status: "", count: 0, current: true },
    {
      name: "Saved as draft",
      color: "bg-pink-500",
      status: "save_as_draft",
      count: 0,
      current: false,
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSaveAsDraft, setCurrentPageSaveAsDraft] = useState(1);
  const [totalPages,setTotalPages]=useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  // const itemsPerPage = 5;
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [addCompanyFilter, setAddCompanyFilter] = useState(null);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  const startIndexSaveAsDraft = (currentPageSaveAsDraft - 1) * itemsPerPage;
  const endIndexSaveAsDraft = startIndexSaveAsDraft + itemsPerPage;

  const displayedData = items?.slice(startIndex, endIndex);
  const displayeSaveAsData = saveAsDraftItems?.slice(startIndexSaveAsDraft, endIndexSaveAsDraft);

//  console.log("display data" , displayedData);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermStatus, setSearchTermStatus] = useState('');


   const filterData = () => {
    return displayedData.filter(item => {
     return searchTerm ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
      item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.company.toLowerCase().includes(searchTerm.toLowerCase()) :
      // item.status.toLowerCase().includes(searchTermStatus.toLowerCase()) 
      searchTermStatus === "active" ? item.status === "active" : item.status.includes(searchTermStatus)
      
   });
  
  }

   

   const filteredData = filterData();

  //  useEffect(() => {
  //   if (searchTerm) {
  //     refreshData()
  //   }
  //  }, [searchTerm])
  
  const handleSearchChange = (event , key) => {
    if (key === "fields") {
      setSearchTerm(event.target.value);  
      // console.log("cuurentPage")
      // setCurrentPage(1)
     }
     else {
      setSearchTermStatus(event.target.value);  
      // setCurrentPage(1)
     }
    
   
  }

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  // Order By
  let curretnOrderByValue = {
    key: "created_at",
    order: "desc",
  };
  if (searchParams.get("order_by")) {
    if (
      searchParams.get("order_by").key !== "created_at" &&
      searchParams.get("order_by").order !== "desc"
    ) {
      const currentOrderByArray = searchParams.get("order_by").split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      };
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue);

  const loadData = (params = {}) => {
    setIsDataLoading(true);

    if (params === {}) {
      //params['status'] = ''
    }
    setSearchParams(params);
    // console.log(page);
    axios
      .get("/employees/index", {
        params: params,
      })
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setItems(response.data.data);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };
  
  const loadSaveAsDraftData = (params = {}) => {
    setIsDataLoading(true);

    if (params === {}) {
      //params['status'] = ''
    }
    setSearchParams(params);
    axios
      .get("/draft/get/employee")
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setSaveAsDraftItems(response.data.draft);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // loadData(pageNumber);
};
const handlePageChangeSaveAsDraft = (pageNumber) => {
  setCurrentPageSaveAsDraft(pageNumber);
};



  function reloadData(key, value) {
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value;

    // Load tab
    // if (key != "page") {
    //   params["page"] = 1;
    // }
    if (value == "save_as_draft") {
      loadSaveAsDraftData(params);
    } else {
      loadData(params);
    }
  }

  // Orders
  function orderBy(key) {
    if (currentOrderBy.order === "desc") {
      reloadData("order_by", key + "-asc");
      setCurrentOrderBy({
        key: key,
        order: "asc",
      });
    } else {
      reloadData("order_by", key + "-desc");
      setCurrentOrderBy({
        key: key,
        order: "desc",
      });
    }
  }



  // Filters
  const [filterCompanyId, setFilterCompanyId] = useState(
    searchParams.get("company_id") ? searchParams.get("company_id") : ""
  );
  function addFilterSwitch(key, value) {
    setFilterStatus(value);
    reloadData(key, value);
  }
  function clearFilter() {
    let params = {};
    setFilterCompanyId("");
    setFilterCount(0);
    loadData(params);
  }

  function refreshData() {
    
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
      setFilterCount((filterCount) => filterCount + 1);
    });
    setAddCompanyFilter("");
    setStatusEmployeeFilter("");
    loadData(params);
    loadSaveAsDraftData(params);
    if(searchTerm){
      setSearchTerm('')
    }
  }

  useEffect(() => {
    refreshData();
  }, []);

  function addFilter(e ,key) {
    e.preventDefault();
    setAddCompanyFilter(e.target.value);
    setIsDataLoading(true);

    const formData = new FormData();

    if(key == "status"){
      formData.append("status", e.target.value);
      setStatusEmployeeFilter(e.target.value);
    }
    else{
      formData.append("name", e.target.value);
      setAddCompanyFilter(e.target.value);
    }

    formData.append("name", e.target.value);
    axios({
      method: "get",
      url: `/employees/index?email=${e.target.value}`,
      // data: formData,
      header: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setItems(response.data.data);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  }

  const [check, setCheck] = useState(null)
  function statusFilter(e) {
    e.preventDefault();
    setCheck(e.target.value);
    setIsDataLoading(true);

     axios({
      method: "get",
      url: `/employees/index?status=${e.target.value}`,
      // data: formData,
      header: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setItems(response.data.data);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  }


  // function addFilter(event, key) {
  //   event.preventDefault();
  //   setIsDataLoading(true);

  //   const formData = new FormData();

  //   if(key == "status"){
  //     formData.append("status", event.target.value);
  //     setStatusEmployeeFilter(event.target.value);
  //   }
  //   else{
  //     formData.append("name", event.target.value);
  //     setAddCompanyFilter(event.target.value);
  //   }
  //   axios({
  //     method: "get",
  //     url: "/employees/index",
  //     data: formData,
  //     header: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   })
  //     .then(function (response) {
  //       // handle success
  //       setIsLoaded(true);
  //       setItems(response.data.date);
  //       setTimeout(function () {
  //         setIsDataLoading(false);
  //       }, 300);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       setError(error);
  //     });
  // }
  // /Filters

  // Methods
  const [deletingItem, setDeletingItem] = useState({ name: null });
  const [mailingItem, setMailingItem] = useState({ name: null });
  const [statusId, setStatusId] = useState("inactive");
  const [itemStatus, setItemStatus] = useState({ name: null });
  const [statusItemModalOpen, setStatusItemModalOpen] = useState(false);
  const [mailItemModalOpen, setMailItemModalOpen] = useState(false);





  function deleteItem(item) {
    setDeletingItem(item);
    setDeleteItemModalOpen(true);
  }

  const sendEmail = (item) => {
    console.log("email" , item)
    setMailingItem(item);
    setMailItemModalOpen(true)
}

const confirmSendEmail = () => {
  debugger
  axios
  .get(`employees/active-email` + mailingItem.id  , {
    _method: "get",
  })
  .then(function (response) {
    setMailItemModalOpen(false);
    refreshData();
    setIsLoaded(true);
    setTimeout(function () {
      setIsDataLoading(false);
    }, 300);
    setMailingItem({ name: null });
  })
  .catch(function (error) {
    setMailItemModalOpen(false);
    refreshData();
    setIsLoaded(true);
    setMailingItem({ name: null });
  });
}

  function statusChangeCompany(item) {
    setStatusId(statusId);
    setItemStatus(item);
    setStatusItemModalOpen(true);
  }

  function confirmDeleteItem() {
    if (filterStatus == "save_as_draft") {
      axios
        .get("draft/delete/" + deletingItem.id, {
          _method: "delete",
        })
        .then(function (response) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setTimeout(function () {
            setIsDataLoading(false);
          }, 300);
          setDeletingItem({ name: null });
        })
        .catch(function (error) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setDeletingItem({ name: null });
        });
    } else {
      axios.delete("employees/delete/" + deletingItem.id, {
          _method: "delete",
        })
        .then(function (response) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setTimeout(function () {
            setIsDataLoading(false);
          }, 300);
          setDeletingItem({ name: null });
        })
        .catch(function (error) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setDeletingItem({ name: null });
        });
    }
  }

  const newArray = displayedData?.map((item) => {
    const words = item?.name?.split(' ');
    const firstTwoWords = words.slice(0, 2); // slice the array to include only the first two elements
    const firstLetters = firstTwoWords.map((word) => {
      return word.charAt(0); // return the first character of each word
    });
    return firstLetters.join(''); // join the first letters to form a new string
  });
  

  
  // /Methods

  function updateItem(item) {
    navigate(`/employee/view/${item.id}`);
  }

  function editItem(item) {
    if(item.status == "draft"){
      
      navigate(`/employee/draft/edit/${item.id}`);

    }else{
    navigate(`/employee/edit/${item.id}`);
    }
  }


  function confirmStatusItem() { 
    axios
      .get(`employees/${itemStatus.status === "active" ? "deactivate" : "active"}` + "/" + itemStatus.id , {
        _method: "get",
      })
      .then(function (response) {
        setStatusItemModalOpen(false);
        refreshData();
        setIsLoaded(true);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
        setItemStatus({ name: null });
      })
      .catch(function (error) {
        setStatusItemModalOpen(false);
        refreshData();
        setIsLoaded(true);
        setItemStatus({ name: null });
      });
  }
 


  if (error) {
    return <Error error={error} />;
  }

  if (!isLoaded) {
    return <Loader fullscreen={false} />;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="min-h-[100vh] bg-gray-50 pb-1">
       {/* upper class name classes overflow-x-hidden  relative  */}
      <NavBar pageTitle={pageTitle}>
        <Link
          to="/employees/create"
          type="button"
          className="relative inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
        >
          Add New Employee
        </Link>
      </NavBar>

      <div>
        <div className="px-4 py-3 col-span-full lg:col-span-3">
          {filterStatus != "save_as_draft" && (
            <Disclosure>
              <div>
                <button
                  onClick={() => refreshData()}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
                >
                  <RefreshIcon
                    className={
                      (isDataLoading ? "animate-spin" : "") + " mr-2 h-5 w-5"
                    }
                    aria-hidden="true"
                  />
                  Refresh
                </button>

                <Disclosure.Button className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                  <FilterIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Filters
                </Disclosure.Button>

                {/* {filterCount > 0 && (
                <button
                  onClick={() => clearFilter()}
                  type="button"
                  className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-600 focus:outline-none focus:ring-0"
                >
                  Clear filters
                  <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </button>
              )} */}
                <div className="float-right">
                  <select
                    className="rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                    name=""
                    id=""
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </select>
                </div>
              </div>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="text-gray-500">
                  <div className="mt-3 px-3 py-3 rounded-lg bg-white shadow">
                    {/* <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="col-span-6">
                      {user.managed_companies.length > 1 && (
        <div className="col-span-12 lg:col-span-3">
            <div className="mt-1 relative rounded-md">
              <form className="pr-3 pt-3 pb-3">
                <input
                  type="text"
                  name="name"
                                placeholder="Search by company name..."
                  value={addCompanyFilter}
                  onChange={(event) => addFilter(event)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  className="p-2 w-full lg:w-2/5 outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded"
                />
                              <div className="float-right">
                                
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                
                  </div> */}
                    <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                      <div>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="name"
                            placeholder="Search by employee name..."
                            value={searchTerm}
                            onChange={(e) => handleSearchChange(e, "fields")}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            id="name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mt-2.5">
                          <select
                            className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={searchTermStatus}
                            onChange={(e) => handleSearchChange(e , "status")}
                          >
                            <option value="">Search by status...</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <div className="mt-2.5"></div>
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </Disclosure>
          )}
          {/* {filterStatus != "save_as_draft" && (
            <div className="col-span-12 lg:col-span-3">
              <div className="mt-1 relative rounded-md">
                <form className="pr-3 pt-3 pb-3">
                  <div className="float-right">
                    <select
                      className="rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                      name=""
                      id=""
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                      <option value="200">200</option>
                 </select>
                </div>
              </form>
            </div>
          </div>
          )} */}
        </div>
      </div>

 {/* Tabs */}
 <Tabs
          tabs={tabs}
          statusTextColors={statusTextColors}
          searchParams={searchParams}
          addFilter={addFilterSwitch}
        />

         {/* Data Table */}
         <div className="mt-0 overflow-x-auto">
          <div className="align-middle inline-block min-w-full border-b border-gray-200">
            {isDataLoading && <SkeletonTable />}
            {!isDataLoading && (
              <table className="min-w-full table-auto">
                <thead>
                  {filterStatus != "save_as_draft" && (
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Action
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Name</span>
                      </th>
                      {filterStatus != "save_as_draft" && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <span className="whitespace-nowrap">EMAIL</span>
                        </th>
                      )}
                      {filterStatus === "save_as_draft" && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <span className="whitespace-nowrap">Action</span>
                        </th>
                      )}
                      {filterStatus === "save_as_draft" && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <span className="whitespace-nowrap">Created At</span>
                        </th>
                      )}
                      {filterStatus != "save_as_draft" && (
                        <th
                          scope="col"
                          className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {/* <button
                          onClick={() => orderBy("created_at")}
                          className="group inline-flex uppercase whitespace-nowrap"
                        > */}
                          STATUS
                          {/* {currentOrderBy.key === "created_at" ? (
                            <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                              {currentOrderBy.order === "desc" ? (
                                <ChevronDownIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ChevronUpIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          ) : (
                            ""
                          )} */}
                          {/* </button> */}
                        </th>
                      )}

                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        COMPANY
                      </th>

                      {/* <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        DEPARTMENT
                      </th> */}
                    </tr>
                  )}
                  {filterStatus == "save_as_draft" && (
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Action
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Name
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Created At</span>
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {filterStatus != "save_as_draft" &&
                    filteredData?.map((item , index) => (
                      <tr
                        className=""
                        key={item.id}
                      >
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                        <button
                            // to="/employee/view/:employeeId"
                            onClick={() => updateItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <EyeIcon
                              className="h-4 w-4 text-blue-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => editItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <PencilAltIcon
                              className="h-4 w-4 text-green-400"
                              aria-hidden="true"
                            />
                          </button>
                          { item.id != 1 && (
                          <button
                            onClick={() => deleteItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                          >
                            <TrashIcon
                              className="h-4 w-4 text-red-400"
                              aria-hidden="true"
                            />
                          </button>
                          )}
                           {item.id != 1 && (
                            <button
                            onClick={() =>
                              statusChangeCompany(
                                item,
                                item?.status == "active" ? "inactive" : "active"
                              )
                            }
                              title={item?.status == "active" ? ("Inactive Company") : ("Active Company")}
                              className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={item?.status == 'active' ? "h-4 w-4 text-red-400 " : "h-4 w-4 text-lime-400" }
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                                />
                              </svg>
                            </button>
                          )}
                           { item.status === "waiting for activation" ? <button
                            onClick={() => sendEmail(item)}
                            className="inline-flex items-center px-2.5 py-2.5 text-[12px] sm:text-[14px] font-semibold  text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <MailIcon
                              className="h-5 w-5 text-[#fbbc04]"
                              aria-hidden="true"
                            />
                          </button> : ""}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                         <span className='rounded-full bg-blue-50 border-1 border-gray-200 p-2 text-xs mr-2 text-blue-400'>{newArray[index]}</span>  {item?.name}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.email}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                        {item?.status == "active" && (
                            <span className="text-lime-400">{item.status}</span>
                          )}
                          {item?.status == "inactive" && (
                            <span className="text-400">{item.status}</span>
                          )}
                           {item?.status == "waiting for activation" && (
                            <span className="text-400">{item.status}</span>
                          )}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.company}
                        </td>
                        {/* <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.name}
                        </td> */}
                      </tr>
                    ))}
                  {filterStatus == "save_as_draft" &&
                    displayeSaveAsData.map((item) => (
                      <tr
                        className="odd:bg-white even:bg-slate-50"
                        key={item.id}
                      >
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                          <button
                            onClick={() => deleteItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                          >
                            <TrashIcon
                              className="h-4 w-4 text-red-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => editItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <PencilAltIcon
                              className="h-4 w-4 text-green-400"
                              aria-hidden="true"
                            />
                          </button>
                          {/* <button
                            onClick={() => updateItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-xs font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <EyeIcon
                              className="h-4 w-4 text-blue-400"
                              aria-hidden="true"
                            />
                          </button> */}
                        </td>
                        {/* <td className="px-6 py-3 whitespace-nowrap text-sm font-semibold text-gray-900">
                        <div className="">
                          <div>
                            <Link
                              to={`/offices/${item.id}/edit`}
                              className="truncate text-blue-700 "
                            >
                              <span>
                                <span className="underline font-bold">
                                  {item.name}
                                </span>
                              </span>
                            </Link>
                          </div>

                          {item.requester ? (
                            <div className="flex items-center mb-1">
                              <div className="flex flex-shrink-0 mr-1">
                                <img
                                  key={"requester_" + item.requester.id}
                                  className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                  src={item.requester.avatar_url}
                                  alt={item.requester.name}
                                />
                              </div>
                              <span className="flex-shrink-0 text-xs leading-5 font-semibold">
                                {item.requester.name}{" "}
                                {item.requester.company.name && (
                                  <span>({item.requester.company.name})</span>
                                )}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </td> */}

                        {/* {user.managed_companies.length > 1 &&
                        !filterCompanyId && (
                          <td className="whitespace-nowrap px-6 py-3 w-full text-sm font-semibold text-gray-900">
                            {item.company.name}
                          </td>
                        )} */}
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.name == null? "-":item.name}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {moment(item.created_at).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {/* /Data Table */}

        {/* Empty state */}
        {!items?.length && isLoaded ? (
          <EmptyStates createPath="/employee/create" />
        ) : (
          ""
        )}


<AlertModal
        title="Delete Company"
        note={`Are you sure you want to delete "${deletingItem.name}" Employee.`}
        confirm={confirmDeleteItem}
        close={() => setDeleteItemModalOpen(false)}
        open={deleteItemModalOpen}
      />

<AlertModal
        title="Update Employee Status"
        note={`Are you sure you want to " ${
          itemStatus.status == "active" ? "Inactive " : "active "
        }  ${itemStatus.name} " Employee.`}
        confirm={confirmStatusItem}
        close={() => setStatusItemModalOpen(false)}
        open={statusItemModalOpen}
      />

<AlertModal
        title="Send Email"
        note={`Are you sure you want to send email to ${mailingItem.name}`}
        confirm={confirmSendEmail}
        close={() => setMailItemModalOpen(false)}
        open={mailItemModalOpen}
      />

{filterStatus != "save_as_draft" &&(
          <PaginationAll
              currentPage={currentPage}
              totalPages={Math.ceil(items?.length / itemsPerPage)}
              onPageChange={handlePageChange}
          />
      )
      }

{filterStatus == "save_as_draft" &&(
          <PaginationAll
              currentPage={currentPageSaveAsDraft}
              totalPages={Math.ceil(saveAsDraftItems?.length / itemsPerPage)}
              onPageChange={handlePageChangeSaveAsDraft}
          />
      )
      }

      </div>
      
      )
}













