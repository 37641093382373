import { useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { TagsInput } from "react-tag-input-component";

export default function TagsField(props) {
    const { label, input, meta, col = 3, required = false } = props
    const [selected, setSelected] = useState(input.value);

    function handleChange(value) {
        setSelected(value)
        input.onChange(value)
    }

    return (
        <div className={'sm:col-span-'+col}>
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <TagsInput
                    value={selected}
                    onChange={(value) => handleChange(value)}
                    name={input.name}
                    placeHolder=""
                />
                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}