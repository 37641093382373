import { useState, useEffect } from "react";
import {
  ExclamationCircleIcon,
  XIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { Country, City } from "country-state-city";
import Select from "react-select";

export default function LocationArrayField(props) {
  const { label, input, meta, columns, required = false } = props;
  const [dataRows, setDataRows] = useState([{ location_name: "", country: "" }]);
  const countries = Country.getAllCountries();
  const [selectedCountry, setSelectedCountry] = useState(input.value.country);

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.name,
    ...country,
  }));
  const [options, setOptions] = useState([updatedCountries]);

  function addDataRow() {
    let newRowData = { location_name: "", country: "" };
    setDataRows([...dataRows, newRowData]);
  }

  // function handleChange(event, dataRowIndex, columnKey) {
  //   const value = event.target.value;
  //   dataRows[dataRowIndex][columnKey] = value;
  //   setDataRows(dataRows);
  //   input.onChange(dataRows);
  // }

  // function handleCountryChange(value, dataRowIndex, columnKey) {
  //   dataRows[dataRowIndex][columnKey] = value.value;
  //   setSelectedCountry(value);
  //   setDataRows(dataRows);
  //   input.onChange(dataRows);
  // }
  const handleChange = (e, idx) => {
    const clone = [...dataRows];
    let obj = clone[idx];
    obj.location_name = e.target.value;
    clone[idx] = obj;
    setDataRows([...clone]);
    input.onChange([...dataRows]);
  };

  const handleCountryChange = (event, idx) => {
    const clone = [...dataRows];
    let obj = clone[idx];
    obj.country = event.value;
    clone[idx] = obj;
    setDataRows([...clone]);
    input.onChange([...dataRows]);
  };

  function removeDataRow(dataRowIndex) {
    let newDataRows = dataRows.slice();
    newDataRows.splice(dataRowIndex, 1);
    setDataRows(newDataRows);
    input.onChange(newDataRows);
  }

  return (
    
    <div className="col-span-12 sm:col-span-6">
      <div className="mt-1 relative">
        {dataRows?.map((dataRow, dataRowIndex) => (
          <div className="relative pr-10" key={dataRowIndex}>
              <div className={"mb-2 grid gap-4 grid-cols-1 sm:grid-cols-2"}>
                <div>
                  <label
                    htmlFor={columns.location_name}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Location Name
                    {required && <span className="text-red-500"> *</span>}
                  </label>
                  <input
                    type="text"
                    onChange={(event) =>
                      handleChange(event, dataRowIndex)
                    }
                    placeholder={columns.location_name}
                    value={dataRow.location_name}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                  />
                </div>
                <div>
                  <label
                    htmlFor={input.name}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country {required && <span className="text-red-500"> *</span>}
                  </label>
                  <Select
                    label="Country"
                    className="placeholder-gray-300"
                    defaultValue={dataRow.country}
                    // onInputChange={dataRow.country}
                    // value={dataRow[dataRowIndex]}
                    options={updatedCountries}
                    onChange={(event) =>handleCountryChange(event, dataRowIndex)}
                    // defaultInputValue={dataRow.country}
                    
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </div>
              </div>
            
            <button
              onClick={() => removeDataRow(dataRowIndex)}
              type="button"
              className="my-5 absolute right-0 top-1 inline-flex items-center p-1 border border-transparent rounded-full  text-white  focus:ring-0"
            >
              {/* <TrashIcon className="h-5 w-5" aria-hidden="true" /> */}
              <TrashIcon className="h-6 w-6 text-red-400" aria-hidden="true"/>
            </button>
          </div>
        ))}
        <div className="flex flex-nowrap justify-center flex-col items-center">
          <div>
            <p className="mt-1 text-sm text-gray-500 ">Add New Location</p>
          </div>
          <button
            onClick={() => addDataRow()}
            type="button"
            className="text-center relative inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75 justify-center"
          >
            <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}
