import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Field, FormSpy, useForm  } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import { ToastContainer, toast } from 'react-toastify';

// Components
import TextareaField from '../../components/fields/TextareaField';
import AlertModal from '../../components/AlertModal';
import UserSelectField from '../../components/fields/UserSelectField';
import DatePickerField from '../../components/fields/DatePickerField';
import ToggleField from '../../components/fields/ToggleField';
import TimePickerField from '../../components/fields/TimePickerField';
import SelectField from '../../components/fields/SelectField';
import AttendanceReasonSelectField from '../../components/fields/AttendanceReasonSelectField';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')

// formDecorator
const formDecorator = createDecorator(
  {
    field: 'user', // when user changes...
    updates: {
      // ...update reason to the result of this function
      reason: () => { return {label: "No Reason Specified",value: null,id: null,company_id: null} }
    }
  },
)

export default function CreateCrud(props) {
  const { user } = props
  let params = useParams()
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([])
  const [attendanceNotes, setAttendanceNotes] = useState([{label: "No Reason Specified",value: null,id: null,company_id: null}]);
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [sendingData, setSendingData] = useState(false)
  const [visibleToRequester, setVisibleToRequester] = useState(true)

  // Set page title
  useEffect(() => {
    document.title = `Create Attendance`;
  }, [])
  
  useEffect(() => {
    axios.get('attendance-reports/create')
      .then(function (response) {
        // handle success
        //console.log(response.data);
        if(response.data.users) {
          setUsers(response.data.users)
          setAttendanceNotes(response.data.attendance_notes)
          setIsLoaded(true);
        } 
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
        setIsLoaded(true);
      })
      .then(function () {
        setIsLoaded(true);
      });
  }, [])

  function closeForm() {
    setAlertModalOpen(false)
    navigate("/attendances");
    
  }

  // Send data
  const sendData = values => new Promise(resolve => {
    setSendingData(true)
    const formData = new FormData();
    formData.append('user_id', values.user.id);
    formData.append('date', values.date);
    formData.append('checkin_at', values.checkin_at);
    formData.append('checkout_at', values.checkout_at);
    formData.append('is_checkin_location_valid', values.is_checkin_location_valid);
    formData.append('is_checkout_location_valid', values.is_checkout_location_valid);
    if(values.note) { formData.append('note', values.note) }
    if(values.reason) { formData.append('checkin_note', values.reason.label) }

    axios.post('attendance-reports', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(function (response) {
        setSendingData(false)
        resolve()
        if(response.data.status === 'created') {
          navigate('/attendances')
        }

        if(response.data.status === 'error') {
          toast.error(response.data.message )
        }
      })
      .catch(function (error) {
        setSendingData(false)
        resolve()
      });
  })

  // Submit form
  const onSubmit = async values => {
    await sendData(values)
  }

  return (
    <div className="h-full">
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        decorators={[formDecorator]}
        initialValues={{ reason: {label: "No Reason Specified",value: null,id: null,company_id: null} }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            {/* Page title & actions */}
            <div className="bg-white border-b border-gray-200 px-4 py-4 flex items-center justify-between sm:px-6 lg:px-8 fixed top-14 lg:top-0 right-0 left-0 lg:left-56  lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
              <div className="flex-1 min-w-0">
                <h1 className="text-lg font-medium leading-6 text-gray-900 truncate">Create Atteddddndance</h1>
              </div>
              <div className="mt-4 flex mt-0 ml-4"> 
                <a href="#" onClick={() => setAlertModalOpen(true)} disabled={sendingData} className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75">
                  Cancel
                </a>
                <button
                  disabled={sendingData}
                  type="submit"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                >
                  {sendingData ? 'Submitting..' : 'Submit'}
                </button>
              </div>
            </div>
            <main className="py-10 pt-20">
              <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-6">
                <div className="shadow sm:rounded-md w-full">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6 relative">
                    {sendingData ? (<div className="bg-white absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
                      Please wait..
                    </div>) : ''}
                    <div>
                      <div> 
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200">
                            <div>
                             
                              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                <Field name="user" validate={required}>  
                                    {({ input, meta }) => (  
                                      <div className={'sm:col-span-3'}>
                                        <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                                            Select User <span className="text-red-500">*</span>
                                        </label>
                                        <div className="mt-1 relative rounded-md shadow-sm">          
                                          <UserSelectField label="" col={3} input={input} meta={meta} values={values} people={users} showCompany={true} />
                                        </div>
                                        {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
                                      </div>
                                    )}
                                </Field>

                                <Field name="date" validate={required}>
                                  {({ input, meta }) => (            
                                    <DatePickerField maxDateToday={true} required={true} label="Date" input={input} meta={meta} />
                                  )}
                                </Field>

                                <Field name="checkin_at" validate={required}>
                                  {({ input, meta }) => (            
                                    <TimePickerField required={true} label="Check-in Time" col={3} input={input} meta={meta} />
                                  )}
                                </Field>

                                <Field name="checkout_at" validate={required}>
                                  {({ input, meta }) => (            
                                    <TimePickerField required={true} minTime={values.checkout_at} required={true} label="Check-out Time" col={3} input={input} meta={meta} />
                                  )}
                                </Field>
                                  
                                <Field name="is_checkin_location_valid">
                                  {({ input, meta }) => (            
                                    <ToggleField label="Check-in location is valid?" input={input} meta={meta} col={3} />
                                  )}
                                </Field>

                                <Field name="is_checkout_location_valid">
                                  {({ input, meta }) => (            
                                    <ToggleField label="Check-out location is valid?" input={input} meta={meta} col={2} />
                                  )}
                                </Field>

                                <Field name="reason">
                                  {({ input, meta }) => (            
                                    <AttendanceReasonSelectField userId={values.user ? values.user.id : null} label="Reason" col={6} input={input} meta={meta} isClearable={true} />
                                  )}
                                </Field>

                                <Field name="note">
                                  {({ input, meta }) => (            
                                    <TextareaField label="Creator Note" col={6} input={input} meta={meta} />
                                  )}
                                </Field>
                              
                                {/* <div>
                                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                                </div> */}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </form>
          
        )}  
      />
      
      <ToastContainer theme="colored" />
      <AlertModal title="Close Form" confirm={closeForm} close={() => setAlertModalOpen(false)} open={alertModalOpen} />
    </div>
  )
}
