import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { MenuAlt1Icon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'

export default function NavBar(props) {  
    return (
      <div className="sticky top-0 flex lg:z-50 h-20 sm:h-14 mt-5 lg:mt-0 flex-shrink-0 bg-white shadow">
      <div className="flex flex-1 justify-between px-1 sm:px-4">
        <div className="flex items-center mt-1 sm:mt-0 h-[100%] my-auto align-middle">
          <h1 className="text-sm sm:text-lg font-medium leading-6 text-gray-900 sm:truncate">{props.pageTitle}</h1>
        </div>
        <div className="flex items-center mb-3 sm:mb-0 h-[100%] my-auto align-middle">
          {props.children}
        </div>
      </div>
    </div>
    );
}