import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  RefreshIcon,
  TrashIcon,
  XIcon,
  PencilAltIcon,
  EyeIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";
import { Disclosure, Transition } from "@headlessui/react";

// Required components
import Loader from "layout/Loader";
import NavBar from "layout/NavBar";
import Pagination from "components/Pagination";
import PaginationAll from "components/pagination";

import EmptyStates from "components/EmptyStates";
import SkeletonTable from "components/SkeletonTable";
import Error from "layout/Error";
import Tabs from "layout/Tabs";
import moment from "moment/moment";
// Optional Components
import AlertModal from "components/AlertModal";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// ====================================== APP ==========================================
// Static variables
const pageTitle = "Companies";

const statusTextColors = {
  open: "text-black",
  pending: "text-purple-500",
  cancelled: "text-gray-500",
  resolved: "text-green-500",
};

export default function List(props) {
  let navigate = useNavigate();

  const { user } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const [filterStatus, setFilterStatus] = useState(
    searchParams.get("status") ? searchParams.get("status") : ""
  );
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
  const [statusItemModalOpen, setStatusItemModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [items, setItems] = useState([]);
  const [saveAsDraftItems, setSaveAsDraftItems] = useState([]);

  // Methods
  const [deletingItem, setDeletingItem] = useState({ name: null });
  const [itemStatus, setItemStatus] = useState({ name: null });
  const [statusId, setStatusId] = useState(0);

  const [paginationMeta, setPaginationMeta] = useState([]);
  const [tabs, setTabs] = useState([
    { name: "Companies", color: "black", status: "", count: 0, current: true },
    {
      name: "Saved as draft",
      color: "bg-pink-500",
      status: "save_as_draft",
      count: 0,
      current: false,
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSaveAsDraft, setCurrentPageSaveAsDraft] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerDraftPage = 15;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [addCompanyFilter, setAddCompanyFilter] = useState(null);
  const [statusCompanyFilter, setStatusCompanyFilter] = useState(null);


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const startIndexSaveAsDraft =
    (currentPageSaveAsDraft - 1) * itemsPerDraftPage;
  const endIndexSaveAsDraft = startIndexSaveAsDraft + itemsPerDraftPage;

  const displayedData = items?.slice(startIndex, endIndex);
  const displayeSaveAsData = saveAsDraftItems?.slice(
    startIndexSaveAsDraft,
    endIndexSaveAsDraft
  );

  const [text, setText] = useState("");

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  // Order By
  let curretnOrderByValue = {
    key: "created_at",
    order: "desc",
  };
  if (searchParams.get("order_by")) {
    if (
      searchParams.get("order_by").key !== "created_at" &&
      searchParams.get("order_by").order !== "desc"
    ) {
      const currentOrderByArray = searchParams.get("order_by").split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      };
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue);

  const loadData = (params = {}) => {
    setIsDataLoading(true);

    if (params === {}) {
      //params['status'] = ''
    }
    setSearchParams(params);
    axios
      .post("/get/companies", {
        params: params,
      })
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setItems(response.data.date);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };
  const loadSaveAsDraftData = (params = {}) => {
    setIsDataLoading(true);

    if (params === {}) {
      //params['status'] = ''
    }
    setSearchParams(params);
    axios
      .get("/draft/get/company")
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setSaveAsDraftItems(response.data.draft);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageChangeSaveAsDraft = (pageNumber) => {
    setCurrentPageSaveAsDraft(pageNumber);
  };
  function refreshData() {

    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
      setFilterCount((filterCount) => filterCount + 1);
    });
    setAddCompanyFilter("");
    setStatusCompanyFilter("");
    loadData(params);
    loadSaveAsDraftData(params);
  }

  useEffect(() => {
    refreshData();
  }, []);

  function reloadData(key, value) {
    let params = {};
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value;

    // Load tab
    // if (key != "page") {
    //   params["page"] = 1;
    // }
    if (value == "save_as_draft") {
      loadSaveAsDraftData(params);
    } else {
      loadData(params);
    }
  }

  // Orders
  function orderBy(key) {
    if (currentOrderBy.order === "desc") {
      reloadData("order_by", key + "-asc");
      setCurrentOrderBy({
        key: key,
        order: "asc",
      });
    } else {
      reloadData("order_by", key + "-desc");
      setCurrentOrderBy({
        key: key,
        order: "desc",
      });
    }
  }

  // Filters
  // const [filterCompanyId, setFilterCompanyId] = useState(searchParams.get("company_id") ? searchParams.get("company_id") : "")

  function addFilterSwitch(key, value) {
    setFilterStatus(value);
    reloadData(key, value);
  }
  // function clearFilter() {
  //   let params = {};
  //   setFilterCompanyId("");
  //   setFilterCount(0);
  //   loadData(params);
  // }

  function addFilter(event, key) {
    event.preventDefault();
    setIsDataLoading(true);

    const formData = new FormData();

    if(key == "status"){
      formData.append("status", event.target.value);
      setStatusCompanyFilter(event.target.value);
    }
    else{
      formData.append("name", event.target.value);
      setAddCompanyFilter(event.target.value);
    }
    axios({
      method: "post",
      url: "/get/companies",
      data: formData,
      header: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // handle success
        setIsLoaded(true);
        setItems(response.data.date);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  }
  // /Filters

  function deleteItem(item) {
    setDeletingItem(item);
    setDeleteItemModalOpen(true);
  }
  function statusChangeCompany(item, statusId) {
    setStatusId(statusId);
    setItemStatus(item);
    setStatusItemModalOpen(true);
  }

  function confirmDeleteItem() {
    if (filterStatus == "save_as_draft") {
      axios
        .get("draft/delete/" + deletingItem.id, {
          _method: "delete",
        })
        .then(function (response) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setTimeout(function () {
            setIsDataLoading(false);
          }, 300);
          setDeletingItem({ name: null });
        })
        .catch(function (error) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setDeletingItem({ name: null });
        });
    } else {
      axios
        .get("delete/company/" + deletingItem.id, {
          _method: "delete",
        })
        .then(function (response) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setTimeout(function () {
            setIsDataLoading(false);
          }, 300);
          setDeletingItem({ name: null });
        })
        .catch(function (error) {
          setDeleteItemModalOpen(false);
          refreshData();
          setIsLoaded(true);
          setDeletingItem({ name: null });
        });
    }
  }

  // company status set

  function confirmStatusItem() {
    axios
      .post("company/status/" + itemStatus.id + "/" + statusId, {
        _method: "post",
      })
      .then(function (response) {
        setStatusItemModalOpen(false);
        refreshData();
        setIsLoaded(true);
        setTimeout(function () {
          setIsDataLoading(false);
        }, 300);
        setItemStatus({ name: null });
      })
      .catch(function (error) {
        setStatusItemModalOpen(false);
        refreshData();
        setIsLoaded(true);
        setItemStatus({ name: null });
      });
  }

  // /Methods

  function updateItem(item) {
    navigate(`/company/view/${item.id}`);
  }

  function editItem(item) {
    if (item.status == "draft") {
      navigate(`/company/draft/edit/${item.id}`);
    } else {
      navigate(`/company/edit/${item.id}`);
    }
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!isLoaded) {
    return <Loader fullscreen={false} />;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="min-h-[100vh] bg-gray-50 pb-1">
      {/* Page title & actions */}
      <NavBar pageTitle={pageTitle}>
        <Link
          to="/company/create"
          type="button"
          className="relative inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0"
        >
          Add New Company
        </Link>
      </NavBar>
      {/* /Page title & actions */}

      <div>
        {/* Filters */}
        <div className="px-4 py-3 col-span-full lg:col-span-3">
          {filterStatus != "save_as_draft" && (
            <Disclosure>
              <div>
                <button
                  onClick={() => refreshData()}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
                >
                  <RefreshIcon
                    className={
                      (isDataLoading ? "animate-spin" : "") + " mr-2 h-5 w-5"
                    }
                    aria-hidden="true"
                  />
                  Refresh
                </button>

                <Disclosure.Button className="ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                  <FilterIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Filters
                </Disclosure.Button>

                {/* {filterCount > 0 && (
                <button
                  onClick={() => clearFilter()}
                  type="button"
                  className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-600 focus:outline-none focus:ring-0"
                >
                  Clear filters
                  <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </button>
              )} */}
                <div className="float-right">
                  <select
                    className="rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                    name=""
                    id=""
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </select>
                </div>
              </div>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="text-gray-500">
                  <div className="mt-3 px-3 py-3 rounded-lg bg-white shadow">
                    {/* <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="col-span-6">
                      {user.managed_companies.length > 1 && (
                        <div className="col-span-12 lg:col-span-3">
                          <div className="mt-1 relative rounded-md">
                            <form className="pr-3 pt-3 pb-3">
                              <input
                                type="text"
                                name="name"
                                placeholder="Search by company name..."
                                value={addCompanyFilter}
                                onChange={(event) => addFilter(event)}
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="p-2 w-full lg:w-2/5 outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded"
                              />
                              <div className="float-right">
                                
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                    
                  </div> */}
                    <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                      <div>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="name"
                            placeholder="Search by company name..."
                            value={addCompanyFilter}
                            onChange={(event) => addFilter(event, "name")}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            id="name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mt-2.5">
                          <select
                            className="block w-full rounded-md border-gray-300 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={statusCompanyFilter}
                            onChange={(event) =>
                              addFilter(event,"status")
                            }
                          >
                            <option value="">Search by status...</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <div className="mt-2.5"></div>
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </Disclosure>
          )}
          {/* {filterStatus != "save_as_draft" && (
            <div className="col-span-12 lg:col-span-3">
              <div className="mt-1 relative rounded-md">
                <form className="pr-3 pt-3 pb-3">
                  <div className="float-right">
                    <select
                      className="rounded-md outline-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-[#ffffff]"
                      name=""
                      id=""
                      value={itemsPerPage}
                      onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          )} */}
        </div>
        {/* /Filters */}

        {/* Tabs */}
        <Tabs
          tabs={tabs}
          statusTextColors={statusTextColors}
          searchParams={searchParams}
          addFilter={addFilterSwitch}
        />

        {/* Data Table */}
        <div className="mt-0 overflow-x-auto">
          <div className="align-middle inline-block min-w-full border-b border-gray-200">
            {isDataLoading && <SkeletonTable />}
            {!isDataLoading && (
              <table className="min-w-full table-auto">
                <thead>
                  {filterStatus != "save_as_draft" && (
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Action
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Name</span>
                      </th>
                      {filterStatus != "save_as_draft" && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <span className="whitespace-nowrap">CEO</span>
                        </th>
                      )}
                      {filterStatus === "save_as_draft" && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <span className="whitespace-nowrap">Action</span>
                        </th>
                      )}
                      {filterStatus === "save_as_draft" && (
                        <th
                          className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          scope="col"
                        >
                          <span className="whitespace-nowrap">Created At</span>
                        </th>
                      )}
                      {filterStatus != "save_as_draft" && (
                        <th
                          scope="col"
                          className="md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {/* <button
                          onClick={() => orderBy("created_at")}
                          className="group inline-flex uppercase whitespace-nowrap"
                        > */}
                          Number of employees
                          {/* {currentOrderBy.key === "created_at" ? (
                            <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                              {currentOrderBy.order === "desc" ? (
                                <ChevronDownIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ChevronUpIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          ) : (
                            ""
                          )} */}
                          {/* </button> */}
                        </th>
                      )}

                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Status
                      </th>

                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Number Of Department
                      </th>
                    </tr>
                  )}
                  {filterStatus == "save_as_draft" && (
                    <tr className="border-t border-gray-200">
                      <th
                        className="px-9 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Action
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        Name
                      </th>
                      <th
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        scope="col"
                      >
                        <span className="whitespace-nowrap">Created At</span>
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {filterStatus != "save_as_draft" &&
                    displayedData.map((item) => (
                      <tr className="" key={item.id}>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                          <button
                            onClick={() => updateItem(item)}
                            title="View Company"
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <EyeIcon
                              className="h-4 w-4 text-blue-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => editItem(item)}
                            title="Edite Company"
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <PencilAltIcon
                              className="h-4 w-4 text-green-400"
                              aria-hidden="true"
                            />
                          </button>
                          {item.id != 1 && (
                            <button
                              onClick={() => deleteItem(item)}
                              title="Delete Company"
                              className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                            >
                              <TrashIcon
                                className="h-4 w-4 text-red-400"
                                aria-hidden="true"
                              />
                            </button>
                          )}
                          {item.id != 1 && (
                            <button
                              onClick={() =>
                                statusChangeCompany(
                                  item,
                                  item?.status == 1 ? 0 : 1
                                )
                              }
                              title={
                                item?.status == 1
                                  ? "Inactive Company"
                                  : "Active Company"
                              }
                              className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={
                                  item?.status == 1
                                    ? "h-4 w-4 text-red-400"
                                    : "h-4 w-4 text-lime-400"
                                }
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                                />
                              </svg>
                            </button>
                          )}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item?.name}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.ceo_name}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item?.number_of_employees}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium  text-left">
                          {item?.status == 1 && (
                            <span className="text-lime-400">Active</span>
                          )}
                          {item?.status == 0 && (
                            <span className="text-red-400">Inactive</span>
                          )}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.no_of_departments}
                        </td>
                      </tr>
                    ))}
                  {filterStatus == "save_as_draft" &&
                    displayeSaveAsData.map((item) => (
                      <tr
                        className="odd:bg-white even:bg-slate-50"
                        key={item.id}
                      >
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                          <button
                            onClick={() => deleteItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700  focus:outline-none focus:ring-0"
                          >
                            <TrashIcon
                              className="h-4 w-4 text-red-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => editItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-[12px] sm:text-[14px] font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <PencilAltIcon
                              className="h-4 w-4 text-green-400"
                              aria-hidden="true"
                            />
                          </button>
                          {/* <button
                            onClick={() => updateItem(item)}
                            className="inline-flex items-center px-2.5 py-1.5 text-xs font-semibold rounded text-gray-700 focus:outline-none focus:ring-0"
                          >
                            <EyeIcon
                              className="h-4 w-4 text-blue-400"
                              aria-hidden="true"
                            />
                          </button> */}
                        </td>
                        {/* <td className="px-6 py-3 whitespace-nowrap text-sm font-semibold text-gray-900">
                        <div className="">
                          <div>
                            <Link
                              to={`/offices/${item.id}/edit`}
                              className="truncate text-blue-700 "
                            >
                              <span>
                                <span className="underline font-bold">
                                  {item.name}
                                </span>
                              </span>
                            </Link>
                          </div>

                          {item.requester ? (
                            <div className="flex items-center mb-1">
                              <div className="flex flex-shrink-0 mr-1">
                                <img
                                  key={"requester_" + item.requester.id}
                                  className="max-w-none h-5 w-5 rounded-full ring-2 ring-white"
                                  src={item.requester.avatar_url}
                                  alt={item.requester.name}
                                />
                              </div>
                              <span className="flex-shrink-0 text-xs leading-5 font-semibold">
                                {item.requester.name}{" "}
                                {item.requester.company.name && (
                                  <span>({item.requester.company.name})</span>
                                )}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </td> */}

                        {/* {user.managed_companies.length > 1 &&
                        !filterCompanyId && (
                          <td className="whitespace-nowrap px-6 py-3 w-full text-sm font-semibold text-gray-900">
                            {item.company.name}
                          </td>
                        )} */}
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {item.name == null ? "-" : item.name}
                        </td>
                        <td className="md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-left">
                          {moment(item.created_at).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {/* /Data Table */}

        {/* Pagination */}
        {/* {items.length && isLoaded ? (
          <Pagination paginationMeta={paginationMeta} reloadData={reloadData} />
        ) : (
          ""
        )} */}
      </div>

      <AlertModal
        title="Delete Company"
        note={`Are you sure you want to delete "${deletingItem.name}" Company.`}
        confirm={confirmDeleteItem}
        close={() => setDeleteItemModalOpen(false)}
        open={deleteItemModalOpen}
      />
      <AlertModal
        title="Update Company Status"
        note={`Are you sure you want to " ${
          itemStatus.status == 1 ? "Inactive " : "Active "
        }  ${itemStatus.name} " Company.`}
        confirm={confirmStatusItem}
        close={() => setStatusItemModalOpen(false)}
        open={statusItemModalOpen}
      />
      {filterStatus != "save_as_draft" && (
        <PaginationAll
          currentPage={currentPage}
          totalPages={Math.ceil(items?.length / itemsPerPage)}
          onPageChange={handlePageChange}
        />
      )}
      {filterStatus == "save_as_draft" && (
        <PaginationAll
          currentPage={currentPageSaveAsDraft}
          totalPages={Math.ceil(saveAsDraftItems?.length / itemsPerDraftPage)}
          onPageChange={handlePageChangeSaveAsDraft}
        />
      )}

      {/* Empty state */}
      {!displayeSaveAsData.length &&
      filterStatus == "save_as_draft" &&
      isLoaded ? (
        <EmptyStates createPath="/company/create" />
      ) : (
        ""
      )}
      {!items.length && filterStatus != "save_as_draft" && isLoaded ? (
        <EmptyStates createPath="/company/create" />
      ) : (
        ""
      )}
    </div>
  );
}
