import { useEffect, useState } from "react";
import classNames from "helpers/classNames";

export default function FormTabs(props) {
  const { tabs, setCurrentTab, defaultTab, values, initialRequiredField , formSelect } =
    props;
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  function changeTab(tabKey) {
    setSelectedTab(tabKey);
    setCurrentTab(tabKey);
  }


console.log("check default" , values)

  if(formSelect === "company"){
    tabs.forEach((tab) => {
      let tabKey = tab.key;
     
      if (initialRequiredField[tabKey] != undefined) {
        for (const [key, value] of Object.entries(initialRequiredField[tabKey])) {
          if (
            key in values === true &&
            values[key] &&
            values[key] !== null &&
            values[key] !== undefined 
            
          ) {
            let getvalue = values[key];

            if (Array.isArray(getvalue)) {
              if (getvalue.length == 0) {
                tab.bgColor = "bg-red-200";
                break;
              } 
              else {
                tab.bgColor = null;
              }
            }
           else if (tab.key === "styles"){
            if (values?.logo?.fileUrl === undefined && typeof values?.logo === "object") {
              tab.bgColor = "bg-red-200";
             
            }
            if (values?.logo_dark?.fileUrl === undefined && typeof values?.logo_dark === "object") {
              tab.bgColor = "bg-red-200";
            }
            else {
              tab.bgColor = null;
            }
           }
            else if (typeof getvalue == "boolean") {
              tab.bgColor = "bg-red-200";
              break;
            } else {
              tab.bgColor = null;
            }
          } else {
            tab.bgColor = "bg-red-200";
            break;
          }

          
        }
      }
    });
  }

  if(formSelect === "employee"){
    tabs.forEach((tab) => {
      let tabKey = tab.key;
     
      if (initialRequiredField[tabKey] != undefined) {
        for (const [key, value] of Object.entries(initialRequiredField[tabKey])) {
          if (
            key in values === true &&
            values[key] &&
            values[key] !== null &&
            values[key] !== undefined 
            
          ) {
            let getvalue = values[key];
            
            if (Array.isArray(getvalue)) {
              if (getvalue.length == 0) {
                tab.bgColor = "bg-red-200";
                break;
              } 
              else {
                tab.bgColor = null;
              }
            }

            else if (typeof getvalue == "boolean") {
              tab.bgColor = "bg-red-200";
              break;
            } else {
              tab.bgColor = null;
            }
          } else {
            tab.bgColor = "bg-red-200";
            break;
          }

          
        }
      }
    });
  }


  return (
    <div className="mb-3">
      <nav className="flex flex-col xl:flex-row" aria-label="Tabs">
        {tabs.map((tab) => (
          <a
            onClick={() => changeTab(tab.key)}
            href={"#" + tab.key}
            key={tab.name}
            className={classNames(
              tab.bgColor !== null
                ? tab.bgColor + " text-gray-800 mr-2"
                : tab.key == selectedTab
                ? "bg-gray-200 text-gray-800 mr-2"
                : "text-gray-600 hover:text-gray-800 mr-2",
              "px-3 py-2 font-medium text-sm rounded-md mr-2"
            )}
            aria-current={tab.key === selectedTab ? "page" : undefined}
          >
            {tab.name}
          </a>
        ))}
      </nav>
    </div>
  );
}
