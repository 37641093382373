import { useState, useEffect, useMemo, useRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup, Map, useMap  } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import { GoogleProvider } from 'leaflet-geosearch';
const geoSearchProvider = new GoogleProvider({ apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY });
//const geoSearchProvider = new OpenStreetMapProvider();

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export default function MapField(props) {
    const { label, input, meta, placeholder = null, col = 3, required = false } = props
    const markerRef = useRef(null)
    const [position, setPosition] = useState(input.value ? input.value : null)

    useEffect(() => {
      input.onChange(position);
    }, [position])

    const eventHandlers = useMemo(
        () => ({
          dragend() {
            const marker = markerRef.current
            if (marker != null) {
              setPosition(marker.getLatLng())
            }
          },
        }),
        [],
    )

    function LocationMarker() {
        const map = useMapEvents({
          click() {
            map.locate()
          },
          locationfound(e) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
          },
        })
      
        return position === null ? null : (
          <Marker 
            position={position}
            eventHandlers={eventHandlers}
            draggable={true}
            ref={markerRef}
        >
            <Popup>Office</Popup>
          </Marker>
        )
    }

    // make new leaflet element
    const Search = (props) => {
      const map = useMap() // access to leaflet map
      const { provider } = props

      useEffect(() => {
          const searchControl = new GeoSearchControl({
              provider,
              style: 'bar',
              showMarker: false,
          })

          map.addControl(searchControl) // this is how you add a control in vanilla leaflet
          return () => map.removeControl(searchControl)
      }, [props])

      map.on('geosearch/showlocation', (event) => {
        setPosition(event.marker.getLatLng())
      });

      return null // don't want anything to show up from this comp
    }

    return (
        <div className={'sm:col-span-'+col}>
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>

            {/* <div className='mb-20'>
              {googleMapsApiKey}
              <Autocomplete
                apiKey={'AIzaSyBvXT8qNIuRSCW2zMqpvc62qKzo2-j38rA'}
                onPlaceSelected={(place) => console.log(place)}
              />
            </div> */}

            <div className="h-[400px] relative">
                <MapContainer
                    style={{height: '100%'}}
                    center={ position ? position : { lat: 51.505, lng: -0.09 }}
                    zoom={13}
                    scrollWheelZoom={false}>
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LocationMarker />
                    <Search provider={geoSearchProvider} />
                </MapContainer>
            </div>

            <p className={ (meta.error && meta.touched) ? 'mt-2 text-sm text-right text-red-600' : 'mt-2 text-sm text-right text-gray-500'}>Please click on the map and allow browser to know your location if asked, you can drag and drop the pin.</p>

            {/* <div>
                <pre>{JSON.stringify(position, 0, 2)}</pre>
            </div> */}

            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}