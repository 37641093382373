import { Form, Field, FormSpy } from 'react-final-form'
import createDecorator from 'final-form-focus'

// Components
import TextField from 'components/fields/TextField';
import MapField from 'components/fields/MapField';
import ToggleField from 'components/fields/ToggleField';
import CompanySingleselectField from 'components/fields/CompanySingleselectField';
import TimezoneSelectField from 'components/fields/TimezoneSelectField';
import NumberField from 'components/fields/NumberField';
import RangeSliderField from 'components/fields/RangeSliderField';
import CreateableMultiSelect from 'components/fields/CreateableMultiSelect';
import FormBuilderField from 'components/fields/FormBuilderField';
import FormPercentage from 'components/forms/FormPercentage';
import FormTabs from 'components/forms/FormTabs';
import { useState } from 'react';
import FormSection from 'components/forms/FormSection';

// Form Multiple Validators
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const required = value => (value ? undefined : 'Required')
const arrayRequired = value => (value.length ? undefined : 'Required')

const focusOnError = createDecorator()

const tabs = [
    { name: 'Information', key: 'information' },
    { name: 'Attributes', key: 'attributes' },
];

export default function CrudForm(props) {
    const { onSubmit, initialValues } = props;
    const [currentTab, setCurrentTab] = useState('information');

    return (  
        <Form
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues}
            //decorators={[focusOnError]}
            render={({ handleSubmit, form, submitting, pristine, values, submitFailed }) => {
            return (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <button id="mainFormSubmit" type="submit"></button>
             
                <FormSpy
                    subscription={{ submitFailed: true }}
                    onChange={(v) => {
                        const el = document.querySelector('.border-red-300')
                        if (el) {
                            const parent = el.closest('.g-form-section');
                            if(parent) {
                                setCurrentTab(parent.dataset.tab);
                                console.log(el);
                                console.log(parent.dataset.tab);
                            }
                            el.scrollIntoView({ behavior: 'smooth' })
                            //
                        }
                    }}
                />

                <FormTabs tabs={tabs} setCurrentTab={setCurrentTab} defaultTab={currentTab} />
               
                <FormSection title="" tab="information" isShow={currentTab === 'information'}>
                    <Field name="form_data">
                        {({ input, meta }) => (            
                        <FormBuilderField required={false} label="Form Builder" input={input} meta={meta} col={6} />
                        )}
                    </Field>

                    <Field name="company_id" validate={required}>
                        {({ input, meta }) => (            
                        <CompanySingleselectField required={true} label="Belongs to company" col={6} input={input} meta={meta} />
                        )}
                    </Field>

                    <Field name="name" validate={required}>
                        {({ input, meta }) => (            
                        <TextField required={true} label="Office Name" input={input} meta={meta} col={6} />
                        )}
                    </Field>
                </FormSection>

                {/* <FormSection title="" isShow={currentTab === 'attributes'}>
                    <Field name="timezone" validate={required}>
                        {({ input, meta }) => (            
                        <TimezoneSelectField required={true} label="Timezone" input={input} meta={meta} col={6} />
                        )}
                    </Field>

                    <Field name="close_payroll_day" validate={required}>
                        {({ input, meta }) => (            
                        <NumberField helpText="min: 1, max: 28" min={1} max={28} required={true} label="Close Payroll Day" input={input} meta={meta} col={6} />
                        )}
                    </Field>

                    <Field name="address">
                        {({ input, meta }) => (            
                        <TextField label="Address" input={input} meta={meta} col={3} />
                        )}
                    </Field>

                    <Field name="phone">
                        {({ input, meta }) => (            
                        <TextField label="Phone" input={input} meta={meta} col={3} />
                        )}
                    </Field>

                    {values.company_id && <Field name="attendance_notes">
                        {({ input, meta }) => (            
                        <CreateableMultiSelect 
                            createParams={{ company_id: values.company_id }} 
                            createPath="attendance-notes"
                            path={"attendance-notes?company_id="+values.company_id} 
                            label="Predefined Notes" 
                            col={6} 
                            input={input} 
                            meta={meta} 
                            description={`Predefined notes which emp going choose from when invalid check-in/out happen either location or timing, by default "No Reason Specified" already attached to each office so no need to add here.`}
                        />
                        )}
                    </Field>}

                    <Field name="is_allow_buffer">
                        {({ input, meta }) => (            
                            <ToggleField label="Allow 15 mins buffer for check-in & check-out timings to be considered as valid?" input={input} meta={meta} col={6} />
                        )}
                    </Field>

                    <Field name="is_notify_hr">
                        {({ input, meta }) => (            
                            <ToggleField label="Notify HR if attendance is invalid?" input={input} meta={meta} col={2} />
                        )}
                    </Field>

                    <Field name="is_notify_line_manager">
                        {({ input, meta }) => (            
                            <ToggleField label="Notify Line Manager if attendance is invalid?" input={input} meta={meta} col={2} />
                        )}
                    </Field>

                    <Field name="is_notify_ceo">
                        {({ input, meta }) => (            
                            <ToggleField label="Notify CEO if attendance is invalid?" input={input} meta={meta} col={2} />
                        )}
                    </Field>

                    <Field name="allowed_range_radius">
                        {({ input, meta }) => (            
                            <RangeSliderField label="Allowed range radius" unit="meters" input={input} meta={meta} col={6} min={10} max={2000} />
                        )}
                    </Field>

                    <Field name="location" validate={required}>
                        {({ input, meta }) => (            
                        <MapField required={true} label="Location" input={input} meta={meta} col="6" />
                        )}
                    </Field>
                
                    {process.env.NODE_ENV === 'development' && <div>
                        <pre>{JSON.stringify(values, 0, 2)}</pre>
                    </div>}
                </FormSection> */}

                <FormSpy subscription={{ values: true }} component={FormPercentage} /> 
            </form>
            )}} 
        />
    )
}