import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

// Components
import AlertModal from "../../components/AlertModal";
import Error from "layout/Error";
import Loader from "layout/Loader";
import NavBar from "layout/NavBar";
import CrudForm from "./components/CrudForm";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

const pageTitle = "CRUD Test: Create Office";

export default function CreateCrud(props) {
    const { user } = props;
    let params = useParams();
    let navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [sendingData, setSendingData] = useState(false);

    // Set page title
    useEffect(() => {
        document.title = pageTitle;
    }, []);

    function closeForm() {
        setAlertModalOpen(false);
        navigate("/crud");
    }

    // Send data
    const sendData = (values) => 
        new Promise((resolve) => {
            setSendingData(true);
            const formData = new FormData();
            formData.append("company_id", values.company_id);
            formData.append("name", values.name);
            if (values.address) {
                formData.append("address", values.address);
            }
            if (values.phone) {
                formData.append("phone", values.phone);
            }
            formData.append("timezone", values.timezone);
            formData.append("close_payroll_day", values.close_payroll_day);
            formData.append("is_notify_hr", values.is_notify_hr);
            formData.append(
                "is_notify_line_manager",
                values.is_notify_line_manager
            );
            formData.append("is_notify_ceo", values.is_notify_ceo);
            formData.append("lat", values.location.lat);
            formData.append("lng", values.location.lng);
            formData.append("is_allow_buffer", values.is_allow_buffer);
            formData.append(
                "allowed_range_radius",
                values.allowed_range_radius
            );
            formData.append(
                "attendance_notes",
                JSON.stringify(values.attendance_notes)
            );

            axios
                .post("officess", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {
                    setSendingData(false);
                    resolve();
                    if (response.data.data.id) {
                        navigate("/crud");
                    }
                })
                .catch(function (error) {
                    setSendingData(false);
                    toast.error(error.message);
                    resolve();
                });
        });

    // Submit form
    const onSubmit = async (values) => {
        await sendData(values);
    };

    if (error) { return <Error error={error} />; }
    if (!isLoaded) { return <Loader fullscreen={false} />; }

    return (
        <div className="h-full">
            {/* Page title & actions */}
            <NavBar pageTitle={pageTitle}>
                <a
                    href="#"
                    onClick={() => setAlertModalOpen(true)}
                    disabled={sendingData}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75"
                >
                    Cancel
                </a>
                <button
                    disabled={sendingData}
                    type="submit"
                    onClick={() => document.getElementById("mainFormSubmit").click()}
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
                >
                    {sendingData ? "Submitting.." : "Submit"}
                </button>
            </NavBar>
            {/* /Page title & actions */}

            <main className="">
                <div className="max-w-3xl mx-auto px-0 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 mt-0 relative pb-0 lg:pb-6">
                    {sendingData && <Loader fullscreen={false} />}
                    
                    <CrudForm
                        onSubmit={onSubmit}
                        initialValues={{ timezone: 'Asia/Kuwait', is_notify_hr: false, is_notify_line_manager: false, is_notify_ceo: false, close_payroll_day: 19, is_allow_buffer: false, allowed_range_radius: 200, }}
                    />
                </div>
            </main>

            <AlertModal
                title="Close Form"
                confirm={closeForm}
                close={() => setAlertModalOpen(false)}
                open={alertModalOpen}
                user={user}
            />

            <ToastContainer theme="colored" />
        </div>
    );
}
