import { Fragment, useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate  } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/solid'
import { FilterIcon } from '@heroicons/react/outline'

// Components
import Pagination from '../../components/Pagination';
import VendorEmptyStates from '../../components/VendorEmptyStates';
import PriorityBadge from '../../components/PriorityBadge';
import TicketShow from './Show';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Helpers
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Search params as a object
const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(search)
  );

  return [searchAsObject, setSearch];
};


export default function List(props) {
  const { user } = props
  const navigate = useNavigate()
  //const [search, setSearch] = useCustomSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // Set page title
  useEffect(() => {
    document.title = `Vendors`;
  }, [])

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { name: 'General', status: 'general', count: 0, current: true },
    { name: 'Cast', status: 'cast', count: 0, current: false },
    { name: 'Crew', status: 'crew', count: 0, current: false },
  ])
  
  // Order By
  let curretnOrderByValue = {
    key: 'created_at',
    order: 'desc',
  }
  if(searchParams.get('order_by')) {
    if(searchParams.get('order_by').key !== 'created_at' && searchParams.get('order_by').order !== 'desc') {
      const currentOrderByArray = searchParams.get('order_by').split("-");
      curretnOrderByValue = {
        key: currentOrderByArray[0],
        order: currentOrderByArray[1],
      }
    }
  }
  let [currentOrderBy, setCurrentOrderBy] = useState(curretnOrderByValue)
  
  
  const apiUrl = '/vendors'
  const loadData = (params = {}) => {
    if(params === {}) {
      params['status'] = 'general'
    }
    //console.log(params)
    setSearchParams(params)

    //console.log('Params', params)
    
    axios.get(apiUrl, {
      params: params
    })
    .then(function (response) {
      // handle success
      setIsLoaded(true);
      setItems(response.data.data);
      setPaginationMeta(response.data.meta)
    })
    .catch(function (error) {
      // handle error
      setIsLoaded(true);
      setError(error);
    })
    .then(function () {
      setIsLoaded(true);
    });
    
  }

  useEffect(() => {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });

    loadData(params)
  }, [])

  function reloadData(key, value) {
    let params = {}
    searchParams.forEach((key, value) => {
      params[value] = key;
    });
    params[key] = value

    // Load tab
    if(key != 'page') {
      params['page'] = 1
    }

    loadData(params)
  }


  function orderBy(key) {
    if(currentOrderBy.order === 'desc') {
      reloadData('order_by', key+'-asc')
      setCurrentOrderBy({
        key: key,
        order: 'asc',
      })
    } else {
      reloadData('order_by', key+'-desc')
      setCurrentOrderBy({
        key: key,
        order: 'desc',
      })
    }
  }

  // Filters
  const [filterCompanyId, setFilterCompanyId] = useState(searchParams.get('company_id') ? searchParams.get('company_id') : '')
  const [filterPriority, setFilterPriority] = useState(searchParams.get('priority') ? searchParams.get('priority') : '')

  function clearFilter() {
    let params = {}
    setFilterCompanyId('')
    setFilterPriority('')
    loadData(params)
  }

  function addFilter(key, value) {
    switch(key) {
      case 'company_id':
        setFilterCompanyId(value)
        break;
      case 'priority':
        setFilterPriority(value)
        break;  
    }
    reloadData(key, value)
  }

  if(!isLoaded) {
    return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
  } else {
    return (
      <div className="min-h-[100vh] bg-white pb-1 relative">
        {/* Page title & actions */}
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 lg:fixed lg:top-0 lg:left-56 lg:right-0 lg:z-10 bg-white">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Vendors</h1>
          </div>
          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0">
                  Create
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item><Link to="/vendors/create" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">General Vendor</Link></Menu.Item>
                    <Menu.Item><Link to="/vendors/create?type=cast" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">Cast</Link></Menu.Item>
                    <Menu.Item><Link to="/vendors/create?type=crew" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">Crew</Link></Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="pt-20">
          <div className="px-4 py-2 flex justify-start">
            <span className="mr-2 inline-block py-2 items-center"><FilterIcon className="h-5 w-5 text-gray-500" aria-hidden="true" /></span>
            {(user.managed_companies.length > 1) && <select
              className="mr-2 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={filterCompanyId}
              onChange={(event) => addFilter('company_id', event.target.value)}
            >
              <option value="">All companies</option>
              {user.managed_companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>}
            <button
              onClick={() => clearFilter()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0"
            >
              Clear all
              <XIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          {/* Tabs */}              
          <div className="grandizer-crud-tabs">
            <div className="hidden sm:block">
              <div className="border-b border-gray-20 px-4">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, tabIndex) => (
                    <button
                      onClick={() => reloadData('status', tab.status)}
                      key={tab.name}
                      className={classNames(
                        (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === 'general'))
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      {tab.name}
                      {tab.count ? (
                        <span
                          className={classNames(
                            (tab.status === searchParams.get('status') || (!searchParams.get('status') && tab.status === 'general')) ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {/* Projects table (small breakpoint and up) */}
          <div className="mt-0 overflow-x-auto">
            <div className="align-middle inline-block min-w-full border-b border-gray-200">
              <table className="min-w-full">
                <thead>
                  <tr className="border-t border-gray-200">
                    <th
                      className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      <span className="whitespace-nowrap">Name</span>
                    </th>
          
                    <th scope="col" className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <button onClick={() => orderBy('created_at')} className="group inline-flex uppercase whitespace-nowrap">
                        Created At
                        { (currentOrderBy.key === 'created_at') ? (<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          { (currentOrderBy.order === 'desc') ? <ChevronDownIcon className="h-4 w-4" aria-hidden="true" /> : <ChevronUpIcon className="h-4 w-4" aria-hidden="true" /> }
                        </span>) : '' }
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {items.map((item) => (
                    <tr key={item.id}>        
                      <td className="px-6 py-3 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="">
                          <div>
                            <Link to={`/vendors/${item.id}`} className="truncate text-blue-700 ">
                              <span>
                                <span className="underline font-bold">{item.name}</span>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                        {item.created_at}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Empty state */}
          {!items.length && isLoaded ? (
            <VendorEmptyStates />
          ) : '' }

          {/* Pagination */}
          {(items.length && isLoaded) ? (<Pagination paginationMeta={paginationMeta} reloadData={reloadData} />) : ''}
          
        </div>
      </div>
    )
  }
}
