import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'

export default function VendorEmptyStates() {

    return (
      <div className="py-32 pb-40">
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No items</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by creating a new item.</p>
          <div className="mt-6">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0">
                  Create
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item><Link to="/vendors/create" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">General Vendor</Link></Menu.Item>
                    <Menu.Item><Link to="/vendors/create?type=cast" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">Cast</Link></Menu.Item>
                    <Menu.Item><Link to="/vendors/create?type=crew" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900">Crew</Link></Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    )
}
