import { ExclamationCircleIcon } from "@heroicons/react/solid";
import axios from "axios";
import { useState, useEffect } from "react";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

export default function UpdateServiceSelectField(props) {
  const {
    label = null,
    input,
    meta,
    placeholder = null,
    col = 3,
    required = false,
  } = props;

  const [error, setError] = useState(null);
  const [departementData, setDepartementData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [userServicesData, setUserServicesData] = useState(
    input.value ? input.value : []
  );
  const [serviceDepartment, setServiceDepartment] = useState({
    serviceName: null,
    departmentId: null,
  });

  useEffect(() => {
    loadData();
    fetchServiceData();
  }, [userServicesData]);

  const loadData = async () => {
    await axios
      .get("/crete/companies-with-departments")
      .then(function (response) {
        setDepartementData(response.data.data.departments);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };

  const fetchServiceData = async () => {
    await axios
      .get("/service/index")
      .then(function (response) {
        setServicesData(response.data?.data);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  };

  function handleChange(e) {
    const departmentId = e.target.value;
    if (departmentId == "") {
      serviceDepartment.serviceName = null;
      serviceDepartment.departmentId = null;
      setServiceDepartment(serviceDepartment);
      setUserServicesData(serviceDepartment.departmentId);
      input.onChange(null);
    } else {
      serviceDepartment.serviceName = label;
      serviceDepartment.departmentId = departmentId;
      setServiceDepartment(serviceDepartment);
      setUserServicesData(serviceDepartment.departmentId);
      input.onChange(serviceDepartment);
    }
  }

  return (
    <>
      <div className={"sm:col-span-" + col}>
        <label
          htmlFor={input.name}
          className="block text-sm font-medium text-gray-700"
        >
          {/* {service?.name}{" "} */}
          {label} {required && <span className="text-red-500">*</span>}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <select
            className={
              meta.error && meta.touched
                ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
            }
            onChange={(e) => handleChange(e)}
            value={userServicesData?.departmentId}
          >
            <option value="">Select...</option>
            {departementData.map((getdata, dataKey) => (
              <>
                <option key={dataKey} disabled>
                  {getdata.name}
                </option>
                {getdata.department.map((departmentlist, key) => (
                  <option value={departmentlist.name} key={key}>
                    {departmentlist.name}
                  </option>
                ))}
              </>
            ))}
          </select>
          {meta.error && meta.touched && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {meta.error && meta.touched && (
          <p className="mt-2 text-sm text-red-600">{meta.error}</p>
        )}
      </div>

      {/* {!servicesData.length && (
        <p className="mt-2 text-sm text-red-600 text-center">
          Services not available!
        </p>
      )} */}
    </>
  );
}
