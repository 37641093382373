import { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import Select from "react-select";
import { Country, City } from "country-state-city"

export default function CountrySelectField(props) {
    const { label, input, meta, required = false } = props
    const countries = Country.getAllCountries();
    const [selectedCountry, setSelectedCountry] = useState(input.value)

    
    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country
      }));

    function handleChange(value) {
        setSelectedCountry(value)
        input.onChange(value.label)
    }
    
    useEffect(() => {
        if(input.value) {
            const selectedIndex = countries.findIndex((country) => country.name === input.value)
        } else {
            const selectedIndex = countries.findIndex((country) => country.name === 'Saudi Arabia')
            if(selectedIndex >= 0) {
                setSelectedCountry(updatedCountries[selectedIndex])
                input.onChange(updatedCountries[selectedIndex].label)
            }
        }
        
    }, [])

    return (
        <div className="sm:col-span-3">
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <Select
                    label="country"
                    onChange={(value) => handleChange(value)}
                    options={updatedCountries}
                    value={selectedCountry}
                />
                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}