import { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { ReactSortable } from "react-sortablejs";
import { DuplicateIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import FormBuilderFieldEditor from './fieldComponents/FormBuilderFieldEditor';

export default function FormBuilderField(props) {
    const { label, input, meta, placeholder = null, col = 3, required = false } = props 
    const [fields, setFields] = useState([
        {
            name: "header-",
            type: "header",
            label: "Header",
            subtype: "h1",
            textAlign: "left",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "paragraph-",
            type: "paragraph",
            label: "Text Block",
            text: "",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "text-",
            type: "text",
            label: "Text Field",
            subtype: "text",
            required: false,
            className: "form-control",
            preFilled: "",
            preFilledEditable: "no",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "number-",
            type: "number",
            label: "Number Field",
            required: false,
            className: "form-control",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "currency-",
            type: "currency",
            label: "Currency Field",
            currency: "SAR",
            required: false,
            className: "form-control",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "textarea-",
            type: "textarea",
            label: "Text Area",
            subtype: "textarea",
            required: false,
            className: "form-control",
            preFilled: "",
            preFilledEditable: "no",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "date-",
            type: "date",
            label: "Date Field",
            required: false,
            className: "form-control",
            preFilled: "",
            preFilledEditable: "no",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "select-",
            type: "select",
            label: "Select Drop-Down",
            values: [
                {
                    label: "Option 1",
                    value: "option-1",
                    selected: true
                },
                {
                    label: "Option 2",
                    value: "option-2",
                    selected: false
                },
            ],
            required: false,
            className: "form-control",
            preFilled: "",
            prefilledBasedOn: '',
            based_on_company: false,
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "boolean-",
            type: "boolean",
            label: "Single Checkbox",
            required: false,
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "checkbox-group-",
            type: "checkbox-group",
            label: "Checkbox Group",
            values: [
                {
                    label: "Option 1",
                    value: "option-1",
                    selected: true
                }
            ],
            required: false,
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "radio-group-",
            type: "radio-group",
            label: "Radio Group",
            values: [
                {
                    label: "Option 1",
                    value: "option-1",
                    selected: false
                },
                {
                    label: "Option 2",
                    value: "option-2",
                    selected: false
                },
            ],
            required: false,
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
        {
            name: "file-",
            type: "file",
            label: "File Upload Field",
            subtype: "single",
            required: false,
            multiple: false,
            className: "form-control",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            fileTypesAllowed: '',
            rtl: false,
            rtl_label: false,
        },
        {
            name: "dbmodel-",
            type: "dbmodel",
            label: "DB Model Select",
            model: "employees",
            required: false,
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
        },
        {
            name: "supplierdata-",
            type: "supplierdata",
            label: "Company Vendor Data",
            data_field: {
                bank_accounts: true,
                phones: true,
                addresses: true,
                vat: true,
                cr: true,
            },
            required: false,
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
        },
        {
            name: "table-",
            type: "table",
            label: "Table",
            columns: [],
            required: false,
            className: "form-control",
            logicFormField: "",
            logicFormFieldOptions: [],
            logicFormFieldMath: '==',
            logicFormFieldValue: '',
            extraConditions: [],
            rtl: false,
            rtl_label: false,
        },
    ]);

    const [formFields, setFormFields] = useState(() => { 
        return input.value ? input.value : [];
    });

    useEffect(() => {
        input.onChange(formFields)
    }, [formFields])

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    function generateRandomId(fieldName) {
        let fieldNameWithId = fieldName+''+getRandomInt(99999999);
        let logicFieldIndex = formFields.findIndex(x => x.name === fieldNameWithId);
        while (logicFieldIndex >= 0) {
            fieldNameWithId = fieldName+''+getRandomInt(99999999);
            logicFieldIndex = formFields.findIndex(x => x.name === fieldNameWithId);
        }
        return fieldNameWithId;
    }

    function cloneField(field) {
        let newField = JSON.parse(JSON.stringify(field));
        newField.name = generateRandomId(newField.name);
        //console.log(newField);
        setEditingField(newField);
        return newField;
    }

    const [editingFieldIndex, setEditingFieldIndex] = useState(null);
    const [editingField, setEditingField] = useState(null);
    function editField(index) {
        console.log('edit', index);
        if(index === null) {
            setEditingFieldIndex(null);
            setEditingField(null);
        } else {
            setEditingFieldIndex(index);
            if(formFields[index]) {
                setEditingField(JSON.parse(JSON.stringify(formFields[index])));
            }
        }   
    }

    function deleteField(index) {
        const name = formFields[index].name.slice();

        formFields.forEach(function(formField) {
            if(formField.logicFormField === name) {
                formField.logicFormField = "";
                formField.logicFormFieldOptions = [];
                formField.logicFormFieldMath = "==";
                formField.logicFormFieldValue = "";
            }
        });
        editField(null);
        let newFormFields = [...formFields];
        newFormFields.splice(index, 1);
        setFormFields(newFormFields);
    }

    function duplicateField(index) {
        let field = JSON.parse(JSON.stringify(formFields[index]));
        field.name = generateRandomId(field.name.substr(0, field.name.indexOf('-'))+'-');
        
        let newFormFields = [...formFields];
        newFormFields.splice(index+1, 0, field);
        setFormFields(newFormFields);
    }

    function updateField(index, newField) {
        let newFormFields = [...formFields];
        newFormFields[index] = newField;
        setFormFields(newFormFields);
    }

    function onChange(evt) {
        //console.log(evt.newIndex);
        if(evt.newIndex !== undefined) {
            editField(evt.newIndex);
        }
    }

    return (
        <div className={'sm:col-span-'+col}>
            <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                {/* Form Builder */}

                <div className="rounded-lg p-2 pb-0 bg-gray-100 dark:bg-dark-1">
                    <ReactSortable 
                        className=""
                        list={fields} 
                        setList={setFields} 
                        group={{ name: 'formBuilder', pull: 'clone', put: false }}
                    >
                        {fields.map((field) => (
                            <div key={field.name} className="inline-block cursor-pointer mr-2 mb-2 whitespace-nowrap rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                                {field.label}
                            </div>
                        ))}
                    </ReactSortable>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-2">
                    <div className="intro-y col-span-12 lg:col-span-3">
                        <div className="rounded-lg p-2 h-full bg-gray-100 dark:bg-dark-1 relative min-h-[300px]">
                            {formFields.length === 0 && <div className="absolute text-center right-0 left-0 top-[40%] h-[20px]">Drag fields to this area</div>}
                        
                            <ReactSortable 
                                className="min-h-[300px]"
                                list={formFields} 
                                setList={setFormFields} 
                                group="formBuilder"
                                clone={cloneField}
                                onChange={onChange}
                            >
                                {formFields.map((formField, index) => (
                                    <div key={formField.name} className={(editingFieldIndex === index) ? 'rounded-lg w-full mb-2 relative p-2 bg-gray-300' : 'bg-white rounded-lg w-full mb-2 relative p-2'} >
                                        {formField.label}
                                        <div className="text-gray-500">{formField.type}</div>
                                        <div className="mt-1 flex justify-end">
                                            <button type="button" className="mr-1" onClick={() => duplicateField(index)}><DuplicateIcon className="h-5 w-5 text-blue-500" aria-hidden="true" /></button>
                                            <button type="button" className="mr-1" onClick={() => editField(index)}><PencilAltIcon className="h-5 w-5 text-gray-500" aria-hidden="true" /></button>
                                            <button type="button" onClick={() => deleteField(index)}><TrashIcon className="h-5 w-5 text-red-500" aria-hidden="true" /></button>
                                        </div>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 lg:col-span-9">
                        <div className="rounded-lg p-2 h-full bg-gray-100 dark:bg-dark-1"> 
                            <FormBuilderFieldEditor fieldIndex={editingFieldIndex} formFieldValue={editingField} updateField={updateField} formFields={formFields} />
                        </div>
                    </div>
                </div>

                {process.env.NODE_ENV === 'development' && <div>
                    <pre>{JSON.stringify(formFields, 0, 2)}</pre>
                </div>}

                {/* /Form Builder */}
                {meta.error && meta.touched && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>}
            </div>
            {meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
        </div>
    )
}