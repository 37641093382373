import React , {useState} from 'react'
import { Switch } from '@headlessui/react';
import { useEffect } from 'react';

export default function IsRemoteToggleFIeld(props) {

    const { input , meta } = props;
    const [enabled, setEnabled] = useState(input.value);
    
    console.log("toggle" , input)

    useEffect(() => {
       setEnabled(input.value)
    }, [input])
    

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }

      function handleChangeRemote(value) {
        setEnabled(value);
        input.onChange(value);
      }


  return (
    <div className="col-span-12 sm:col-span-6">
        <div className='flex gap-2'>
        <Switch
    {...input}
checked={enabled}
onChange={handleChangeRemote}
className={classNames(
enabled ? "bg-indigo-600" : "bg-gray-200",
"relative inline-flex flex-shrink-0 h-6 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
)}
>
<span
aria-hidden="true"

className={classNames(
enabled ? "translate-x-8" : "translate-x-0",
"pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
)}
>

</span>
</Switch>
        <p>Remote</p>
        </div>
 

  {meta.error && meta.touched && (
    <p className="mt-2 text-sm text-red-600">{meta.error}</p>
  )}
</div>
  )
}
