import React from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon
} from "@heroicons/react/solid";

const PaginationAll = ({ currentPage, totalPages, onPageChange }) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
  };

  return (
    <div className="flex justify-center mt-6 mb-3">
      <nav className="inline-flex gap-2">
      
        { isFirstPage ?   <button className="border-t-4 border-transparent inline-flex my-auto items-center" disabled>Previous</button> : <button className="border-t-4 border-transparent inline-flex my-auto items-center"   onClick={() => handlePageChange(currentPage - 1)}><ChevronDoubleLeftIcon className='h-4 w-4 text-black my-auto items-center' /> Previous </button> }
   
        {/* {Array.from({ length: totalPages }, (_, i) => (
          <a
            key={i}
            href="#"
            className={`px-3 py-1 ${
              currentPage === i + 1
                ? 'border-t-4 border-blue-600 text-black'
                : 'border-t-4 border-transparent hover:border-gray-300'
            }`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </a>
        
        ))} */}
          
          <a className='border-t-4 border-blue-600 text-black w-[30px] text-center'>{currentPage}</a>
       
            { isLastPage ?   <button className="border-t-4 border-transparent inline-flex my-auto items-center" disabled>Next</button> : <button className="border-t-4 border-transparent inline-flex my-auto items-center"  onClick={() => handlePageChange(currentPage + 1)}>Next<ChevronDoubleRightIcon className='h-4 w-4 text-black my-auto items-center'/> </button> }
  
      </nav>
    </div>
  );
};

export default PaginationAll;