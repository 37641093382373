
import React from 'react';
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { useParams , useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import { EyeIcon } from "@heroicons/react/solid";



export default function EmployeeView() {
    // const roleData = [];

    let {employeeId} = useParams();
    const [employeeInfo, setEmployeeInfo] = useState({});

    // /employees/show/301

    const fileGetURL = process.env.REACT_APP_FILE_STARTURL;

    let navigate = useNavigate();

    useEffect(() => {
        getEmployeeInfo();
    }, []);

  

    async function getEmployeeInfo() {
        await axios
            .get("employees/show/" + employeeId, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(function (response) {
                // setSendingData(false);
                // resolve()
                console.log(response.data.data,'moheib');

                setEmployeeInfo(response.data.data);
                // setDepartmentInfo(response.data.data);
                // setCEOInfo(response.data.data.ceo);
                // setCompanyData(true);

                // navigate("/companies");
            })
            .catch(function (error) {
                toast.error(error.response.data.message);
            });

        //     await axios
        // .get("/crete/companies-with-departments")
        // .then(function (response) {
        //   setDepartementData(response.data.data.departments);
        // })
        // .catch(function (error) {
        //   // handle error
        //   // setError(error);
        // });
    }

  
    // console.log("business hours view" , JSON.parse(employeeInfo?.business_hours))

  return (
    <div className='overflow-hidden'>
        <div className='flex m-5'>
            <div>
              <h4 className='text-[22px] font-bold'>Employee Profile</h4>
            </div>
            <div>
              {/* <button>Back</button> */}
            </div>
        </div>      
        <div className="bg-white rounded-md sm:px-5 px-0 mb-5 sm:mx-5 mx-0 pl-2 ">
            <h4 className="font-bold text-[20px] py-5 border-b-2 my-5">Personal Information</h4>
            <div className='bg-white border-b-2 my-6 rounded-md sm:p-5 p-0 sm:mx-5 mx-0'>
                <div className='grid md:grid-cols-3 grid-cols-1'>
                    <div className='col-span-2'>
                        <div className='lg:flex'>
                        <div className='sm:w-[30%]'>
                            <img className='w-[50%] md:w-[70%] mx-auto text-center rounded-full border-2 mt-10 sm:mt-0' src={employeeInfo?.profile_pic_url ?  fileGetURL +  employeeInfo?.profile_pic_url : "https://png.pngtree.com/png-vector/20220901/ourmid/pngtree-company-employee-avatar-icon-wearing-a-suit-png-image_6133899.png" } alt="profile" />
                        </div>
                        <div className='lg:my-auto lg:align-middle sm:text-left text-center  px-1 sm:px-0'>
                            <h4 className='text-[18px] font-bold'>{employeeInfo?.name ? employeeInfo.name : "N/A"}</h4>
                            <p className='text-[18px] font-medium'>{employeeInfo?.user_profile?.job_title ? employeeInfo?.user_profile?.job_title : "N/A"}</p>
                            <p className='pb-2'>Acquaint Communications / Media Buying Active </p>
                            <span className='border-2 px-2 py-1 rounded-2xl bg-[rgb(113,128,150)] text-white'>{employeeInfo?.status ? employeeInfo?.status : "N/A"}</span>
                        </div>
                        </div>
                    </div>
                    <div className='xl:my-auto xl:align-middle pl-2 sm:pl-0 py-3 lg:py-0'>
                        <h4 className="font-bold text-[20px] md:pb-6">Contact Details</h4>
                        <MailIcon className='h-5 w-5 float-left'/>
                        <span className='float-left'>&thinsp; {employeeInfo?.email ? employeeInfo?.email : "N/A"}</span> <br />
                        <PhoneIcon className='h-5 w-5 float-left'/><span className='float-left'>&thinsp;{employeeInfo?.user_profile?.cellphone_number ? employeeInfo?.user_profile?.cellphone_number : "N/A"}</span>
                        {/* <div>
                            <tr className="grid grid-flow-row sm:flex">
                                <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Email:</td>
                                <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5" >
                                    <MailIcon className='h-5 w-5 float-left'/>
                                    <span className='float-left'>&thinsp;abdallah.e@acq-c.com</span>
                                </td>
                            </tr>
                            <tr className="grid grid-flow-row sm:flex">
                                <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Contact:</td>
                                <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                                    <PhoneIcon className='h-5 w-5 float-left'/><span className='float-left'>&thinsp;201100393003</span> 
                                </td>
                            </tr>
                        </div> <br />
                        <div>
                        </div> */}
                    </div>
                </div>
            </div>
              <div className="grid xl:grid-cols-2 pb-1">
                  <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Arabic Name:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.name_arabic ? employeeInfo?.user_profile?.name_arabic : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Join Date:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5" >
                        {employeeInfo?.user_profile?.joining_date  ? moment(employeeInfo?.user_profile?.joining_date).format("DD/MM/YYYY")
                : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">D.O.B:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.dob ? moment(employeeInfo?.user_profile?.dob).format("DD/MM/YYYY")
                : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Gender:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.gender ? employeeInfo?.user_profile?.gender : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Nationality:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.nationality ? employeeInfo?.user_profile?.nationality : "N/A"}
                        </td>
                    </tr>
                  </div>
                  <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Hometown Address:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.hometown_address ? employeeInfo?.user_profile?.hometown_address : "N/A"} 
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Local Address:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.local_address ? employeeInfo?.user_profile?.local_address : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Resident Country:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.res_country ? employeeInfo?.user_profile?.res_country : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Marital Status:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.marital_status ? employeeInfo?.user_profile?.marital_status : "N/A"}
                      </td>
                  </tr>
                  </div>
              </div>
        </div>
        <div className="bg-white rounded-md sm:px-5 px-0 mb-5 sm:mx-5 mx-0 pl-2 ">
          <h4 className="font-bold text-[20px] py-5 border-b-2 my-5">Contact Information</h4>
          <div className="grid xl:grid-cols-2">
              <div> 
                <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Corporate's Email:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                       {employeeInfo?.email ? employeeInfo?.email : "N/A"} 
                      </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Personal Email:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                       {employeeInfo?.user_profile?.personal_email ? employeeInfo?.user_profile?.personal_email : "N/A"} 
                      </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Cellphone Number:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                       {employeeInfo?.user_profile?.cellphone_number ? employeeInfo?.user_profile?.cellphone_number : "N/A"} 
                      </td>
                </tr>  
                <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Alternative Cellphone Number:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                       {employeeInfo?.user_profile?.alternative_mobile_country ? employeeInfo?.user_profile?.alternative_mobile_country : "N/A"} 
                      </td>
                </tr>             
              </div>
              <div>
                <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Emergency Contact Relationship:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                       {employeeInfo?.user_profile?.emergency_contact_relationship ? employeeInfo?.user_profile?.emergency_contact_relationship : "N/A"} 
                      </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex">
                    <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Emergency Contact Name:</td>
                    <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                    {employeeInfo?.user_profile?.emergency_contact_name ? employeeInfo?.user_profile?.emergency_contact_name : "N/A" }
                    </td>
                </tr>

                <tr className="grid grid-flow-row sm:flex">
                    <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Emergency Contact Mobile Number:</td>
                    <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.emergency_contact_mobile_number ? employeeInfo?.user_profile?.emergency_contact_mobile_number : "N/A"}
                    </td>
                </tr>
              </div>
          </div>
        </div>
        <div className="bg-white rounded-md sm:px-5 px-0 mb-5 sm:mx-5 mx-0 pl-2 ">
          <h4 className="font-bold text-[20px] py-5 border-b-2 my-5">Bank & Passport Information</h4>
          <div className="grid xl:grid-cols-2">
              <div> 
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Passport No:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.passport_no ? employeeInfo?.user_profile?.passport_no : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Passport File:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      <a href="#">
                    {employeeInfo?.user_profile?.passport_fileurl &&
                    employeeInfo?.user_profile?.passport_fileurl != "" &&
                    employeeInfo?.user_profile?.passport_fileurl != "null" ? (
                        <a href={fileGetURL + employeeInfo?.user_profile?.passport_fileurl} target="_blank">
                            <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                        </a> 
                        ) : (
                            "N/A"
                        )}
                        </a>
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Bank Name:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                       {employeeInfo?.user_profile?.bank_name ? employeeInfo?.user_profile?.bank_name : "N/A" }    
                      </td>
                  </tr>
              </div>
              <div>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Passport Expire Date:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.passport_expire_date ? employeeInfo?.user_profile?.passport_expire_date : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">IBAN:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.iban ? employeeInfo?.user_profile?.iban : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">IBAN File:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.iban_fileurl &&
                    employeeInfo?.user_profile?.iban_fileurl != "" &&
                    employeeInfo?.user_profile?.iban_fileurl != "null" ? (
                        <a href={fileGetURL + employeeInfo?.user_profile?.iban_fileurl} target="_blank">
                        <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                            </a> 
                            ) : (
                                "N/A"
                            )}
                      </td>
                  </tr>
              </div>
          </div>
        </div>
        <div className="bg-white rounded-md sm:px-5 px-0 mb-5 sm:mx-5 mx-0 pl-2 ">
          <h4 className="font-bold text-[20px] py-5 border-b-2 my-5">Company Information</h4>
          <div className="grid xl:grid-cols-2">
              <div> 
              <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Joining Date:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.joining_date ? employeeInfo?.user_profile?.joining_date : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Additional roles:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5 space-x-1">

                        { employeeInfo?.roles != ""?
                        employeeInfo?.roles?.map((e,index)=>(
                            <td className="space-x-1 border-2 bg-gray-500 mr-3 px-5" key={index}>
                                {e.name}
                            </td>
                        )):"N/A"}
                      </td>
                      
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Company Location:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.company_location_id ? employeeInfo?.company_location_id : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Position:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.position_name ? employeeInfo?.user_profile?.position_name : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Division:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.division_name ? employeeInfo?.user_profile?.division_name : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Job Title (Arabic):</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.job_title_ar ? employeeInfo?.user_profile?.job_title_ar : "N/A"}
                      </td>
                  </tr>
              </div>
              <div>
              <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Leaving Date:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.leaving_date ? employeeInfo?.user_profile?.leaving_date : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Company:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.assign_company ? employeeInfo?.assign_company : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">SponserShip:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.sponsorship ? employeeInfo?.user_profile?.sponsorship : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Department:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.assign_department ? employeeInfo?.assign_department : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Job Title:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.job_title ? employeeInfo?.user_profile?.job_title : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Line Manager:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.line_manager_name ? employeeInfo?.line_manager_name : "N/A"}
                      </td>
                  </tr>
                  {/* <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Number of Wife:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.number_of_wife ? employeeInfo?.user_profile?.number_of_wife : "N/A"}
                      </td>
                  </tr>
                  <tr className="grid grid-flow-row sm:flex">
                      <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Number of Children:</td>
                      <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      {employeeInfo?.user_profile?.number_of_children ? employeeInfo?.user_profile?.number_of_children : "N/A"}
                      </td>
                  </tr> */}
              </div>
          </div>
        </div>
        
        <div className="bg-white rounded-md sm:p-5 p-0 mb-5 sm:mx-5 mx-0 pl-2 ">
            <h4 className="font-bold text-[20px]  border-b-2 pb-4 pt-4 sm:p-0">Iqama & Medical Information</h4>
            <div className="grid xl:grid-cols-2 mt-8">
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Iqama ID:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                            {employeeInfo?.user_profile?.national_iqama_id ? employeeInfo?.user_profile?.national_iqama_id : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">qama ID File:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      <a href="#">
                    {employeeInfo?.user_profile?.national_iqama_id_fileurl &&
                    employeeInfo?.user_profile?.national_iqama_id_fileurl != "" &&
                    employeeInfo?.user_profile?.national_iqama_id_fileurl != "null" ? (
                        <a href={fileGetURL + employeeInfo?.user_profile?.national_iqama_id_fileurl} target="_blank">
                            <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                        </a> 
                        ) : (
                            "N/A"
                        )}
                        </a>
                      </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Medical Membership No:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                            {employeeInfo?.user_profile?.medical_membership_no ? employeeInfo?.user_profile?.medical_membership_no : "N/A"}
                        </td>
                    </tr>
                </div>
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Iqama ID Expire Date:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.national_iqama_id_expire_date ? employeeInfo?.user_profile?.national_iqama_id_expire_date : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Membership No:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.medical ? employeeInfo?.user_profile?.medical : "N/A"}
                        </td>
                    </tr>
                </div>
            </div>
        </div>
{/* 
        <div className="bg-white rounded-md sm:p-5 p-0 mb-5 sm:mx-5 mx-0 pl-2 ">
            <h4 className="font-bold text-[20px]  border-b-2 pb-4 pt-4 sm:p-0">Workflow</h4>
            <div className="grid xl:grid-cols-2 mt-8">
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Submitted Requests:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                            {employeeInfo?.requestCount ? employeeInfo?.requestCount : employeeInfo.requestCount===0 ? 0 : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Approved:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                            {employeeInfo?.approvedRequestCount ? employeeInfo?.approvedRequestCount :employeeInfo.approvedRequestCount === 0 ? 0 : "N/A"}
                        </td>
                    </tr>
                </div>
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Rejected:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                            {employeeInfo?.rejectedRequestCount ? employeeInfo?.rejectedRequestCount :employeeInfo?.rejectedRequestCount===0?0: "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Cancelled:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                            {employeeInfo?.cancelledRequestCount ? employeeInfo?.cancelledRequestCount :employeeInfo?.cancelledRequestCount===0?0: "N/A"}
                        </td>
                    </tr>
                </div>
            </div>
        </div> */}

<div className="bg-white rounded-md sm:p-5 p-0 mb-5 sm:mx-5 mx-0 pl-2 ">
            <h4 className="font-bold text-[20px]  border-b-2 pb-4 pt-4 sm:p-0">Job Type</h4>
            <div className="grid xl:grid-cols-2 mt-8">
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Contract Type:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.job_type ? employeeInfo?.job_type : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Year of Contract:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.contracts?.[0]?.year  ? employeeInfo?.contracts?.[0]?.year  : "N/A"}                        
                      </td>
                    </tr>
                </div>
                <div>
                <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Contract file:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                      <a href="#">
                    {employeeInfo?.contracts?.[0]?.file_url &&
                    employeeInfo?.contracts?.[0]?.file_url != "" &&
                    employeeInfo?.contracts?.[0]?.file_url != "null" ? (
                        <a href={fileGetURL + employeeInfo?.contracts?.[0]?.file_url} target="_blank">
                            <EyeIcon className="h-4 w-4 text-blue-400 ml-2 hover:text-blue-500" />
                        </a> 
                        ) : (
                            "N/A"
                        )}
                        </a>
                      </td>
                </tr>
                <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Remote_Job:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.is_remote?
                         employeeInfo?.is_remote === false ?  "Not_Remote"  : "Remote"
                        :"N/A"}                        
                      </td>
                    </tr>    
                </div>
            </div>
        </div>  

        <div className="bg-white rounded-md sm:p-5 p-0 mb-5 sm:mx-5 mx-0 pl-2 ">
            <h4 className="font-bold text-[20px]  border-b-2 pb-4 pt-4 sm:p-0">Salary Details</h4>
            <div className="grid xl:grid-cols-2 mt-8">
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">basic:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                            {employeeInfo?.user_profile?.salary_details[0] ? employeeInfo?.user_profile?.salary_details[0] : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">housing:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.salary_details[1] ? employeeInfo?.user_profile?.salary_details[1] : "N/A"}
                        </td>
                    </tr>
                </div>
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">transpiration:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.salary_details[2] ? employeeInfo?.user_profile?.salary_details[2] : "N/A"}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">total of:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {employeeInfo?.user_profile?.salary_details[3] ? employeeInfo?.user_profile?.salary_details[3] : "N/A"}
                        </td>
                    </tr>
                </div>
            </div>
        </div>


        <div className="bg-white rounded-md sm:p-5 p-0 mb-5 sm:mx-5 mx-0 pl-2 ">
            <h4 className="font-bold text-[20px]  border-b-2 pb-4 pt-4 sm:p-0">Working Hours</h4>
            <div className="grid xl:grid-cols-2 mt-8">
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Sunday:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {/* 09:00 AM - 06:00 PM */}
                        {employeeInfo?.business_hours?.sunday?.map(item => (
                            // {item?.open.includes("AM") ? "" : "AM" } {item?.close.includes("PM") ? "" : "PM" }
                            item?.open && item.close ?   <p>{item?.open.slice(0, 2)}{item?.open.includes(":") ? "" : ":" }{item?.open.slice(2)} - {item?.close.slice(0, 2)}{item?.close.includes(":") ? "" : ":" }{item?.close.slice(2)}</p>  : "N/A"
                        ))}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Monday:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {/* 09:00 AM - 06:00 PM */}
                        {employeeInfo?.business_hours?.monday?.map(item => (
                        item?.open && item.close ?   <p>{item?.open.slice(0, 2)}{item?.open.includes(":") ? "" : ":" }{item?.open.slice(2)} - {item?.close.slice(0, 2)}{item?.close.includes(":") ? "" : ":" }{item?.close.slice(2)}</p>  : "N/A"
                        ))}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Tuesday:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {/* 09:00 AM - 06:00 PM */}
                        {employeeInfo?.business_hours?.tuesday?.map(item => (
                        item?.open && item.close ?   <p>{item?.open.slice(0, 2)}{item?.open.includes(":") ? "" : ":" }{item?.open.slice(2)} - {item?.close.slice(0, 2)}{item?.close.includes(":") ? "" : ":" }{item?.close.slice(2)}</p>  : "N/A"
                        ))}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Wednesday:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {/* 09:00 AM - 06:00 PM */}
                        {employeeInfo?.business_hours?.wednesday?.map(item => (
                            item?.open && item.close ?   <p>{item?.open.slice(0, 2)}{item?.open.includes(":") ? "" : ":" }{item?.open.slice(2)} - {item?.close.slice(0, 2)}{item?.close.includes(":") ? "" : ":" }{item?.close.slice(2)}</p>  : "N/A"
                        ))}
                        </td>
                    </tr>
                </div>
                <div>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Thursday:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {/* 09:00 AM - 06:00 PM */}
                        {employeeInfo?.business_hours?.thursday?.map(item => (
                        item?.open && item.close ?   <p>{item?.open.slice(0, 2)}{item?.open.includes(":") ? "" : ":" }{item?.open.slice(2)} - {item?.close.slice(0, 2)}{item?.close.includes(":") ? "" : ":" }{item?.close.slice(2)}</p>  : "N/A"
                        ))}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Friday:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {/* Not Available */}
                        {employeeInfo?.business_hours?.friday?.map(item => (
                        item?.open && item.close ?   <p>{item?.open.slice(0, 2)}{item?.open.includes(":") ? "" : ":" }{item?.open.slice(2)} - {item?.close.slice(0, 2)}{item?.close.includes(":") ? "" : ":" }{item?.close.slice(2)}</p>  : "N/A"
                        ))}
                        </td>
                    </tr>
                    <tr className="grid grid-flow-row sm:flex">
                        <td className="font-semibold sm:w-[30%] w-[100%] sm:pb-5 pb-0">Saturday:</td>
                        <td className="sm:pl-[10%] pl-0 sm:w-[70%] w-[100%] sm:pb-0 pb-5">
                        {/* Not Available */}
                        {employeeInfo?.business_hours?.saturday?.map(item => (
                            item?.open && item.close ?   <p>{item?.open.slice(0, 2)}{item?.open.includes(":") ? "" : ":" }{item?.open.slice(2)} - {item?.close.slice(0, 2)}{item?.close.includes(":") ? "" : ":" }{item?.close.slice(2)}</p>  : "N/A"
                        ))}
                        </td>
                    </tr>
                </div>
            </div>
        </div>

    </div>
  )
}