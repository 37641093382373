

import React, { useState, useEffect } from "react";

function DragLetterHeadImage({handleDragStart , x , y , values , id}) {

  useEffect(() => {
    console.log(values , "v")
   }, [values])

  return (
  
    <img
      id= {id}
      draggable="true"
      onDragStart={handleDragStart}
      src={values}
      style={{ position: "absolute", left: x, top: y , width:"100px" , height:"100px" , zIndex:"2" , cursor: "pointer" }}
    />

  );
}

export default DragLetterHeadImage;