import React from 'react'
import { ToastContainer , toast } from 'react-toastify'
import { useEffect , useState } from 'react';
import NavBar from 'layout/NavBar';
import Loader from 'layout/Loader';
import UpdateCrudForm from './components/UpdateCrudForm';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const pageTitle = "Edit Department";

export default function Edit(props) {

    let { editDepartmentId } = useParams();

    const [sendingData, setSendingData] = useState(false);
    const [stateData, setStateData] = useState(true);
    const [recevingData, setRecevingData] = useState(false);
    const [errorHold, setErrorHold] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [model, setModel] = useState({});

    useEffect(() => {
        getDepartmentInfo();
    }, [])
    

  async function getDepartmentInfo() {
    setIsLoaded(false);
    await axios
      .get("departments/edit/" + editDepartmentId , {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
    
      console.log("response employee" , response)

        setModel(response.data.data);   
        setRecevingData(true);
        setTimeout(function () {
          setIsLoaded(true);
        }, 1000);
      })
      .catch(function (error) {
        toast.error(error);
      });


  }

  let initialValues = {
    color: "",
    text_color: "",
    name: "",
    choose_existing_employee_to_manager: false,
}

const onSubmit = async (values) => {
    console.log("onSUbmit" , values)
    // await sendData(values);
  }

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);


  return (
    <div className="h-full">
        <ToastContainer/>
        <NavBar pageTitle={pageTitle}>
        <a
          href="#"
        //   onClick={() => setAlertModalOpen(true)}
          disabled={sendingData}
          className="inline-flex items-center px-[10px] py-[5px] sm:px-3 sm:py-2 border border-gray-300 shadow-sm text-xs sm:text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-[3px] sm:mr-2 disabled:opacity-75"
        >
          Cancel
        </a>
        <button
          disabled={stateData}
          type="submit"
          onClick={() => document.getElementById("mainFormSubmitDepartment").click()}
          className="relative inline-flex items-center px-[10px] py-[5px] sm:px-4 sm:py-2 border border-transparent shadow-sm text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
        >
          {sendingData ? "Submitting.." : "Create"}
        </button>
      </NavBar>
      {/* /Page title & actions */}

      <main className="">
      <div className="max-w-3xl mx-auto px-0 sm:px-6  md:space-x-5 lg:max-w-full lg:px-8 mt-0 relative pb-0 lg:pb-6">
      {sendingData && <Loader fullscreen={false} />}
       
       {/* { recevingData && ( */}
         <UpdateCrudForm
          onSubmit = {onSubmit}
          initialValues={{ ...model }}
          setButtonActive={initialValues}
          savaButtonAction={(val) => setStateData(val)}
          
        
       /> 
       {/* ) } */}

      </div>
        </main>
        </div>
  )
}
