import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";

// Components
import AlertModal from "../../components/AlertModal";
import Error from "layout/Error";
import Loader from "layout/Loader";
import NavBar from "layout/NavBar";
import UpdateCrudForm from "./components/UpdateCrudForm";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

const pageTitle = "Update Company";
export default function Edit(props) {
  const { user } = props;
  let companyId = useParams();
  const [model, setModel] = useState({});

  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [recevingData, setRecevingData] = useState(false);
  const [stateData, setStateData] = useState(true);
  const [locationData, setLocationData] = useState([]);
  const [bankAccountData, setBankAccountData] = useState([]);
  const [creditCardsData, setCreditCardsData] = useState([]);
  const [CEOId, setCEOId] = useState(null);
  const [tabSelect, setTabSelect] = useState("company");
  const [nationalAddressFile, setNationalAddressFile] = useState({
    fileUrl: null,
    fileName: null,
  });

  const [crNumberFile, setCRNumberFile] = useState({
    fileUrl: null,
    fileName: null,
  });
  const [gosiFile, setGosiFile] = useState({ fileUrl: null, fileName: null });
  const [vatFile, setVatFile] = useState({ fileUrl: null, fileName: null });
  const [cocFile, setCocFile] = useState({ fileUrl: null, fileName: null });
  const [gaztFile, setGaztFile] = useState({ fileUrl: null, fileName: null });
  const [municipalityFile, setMunicipalityFile] = useState({
    fileUrl: null,
    fileName: null,
  });
  const [bankAccountFile, setBankAccountFile] = useState({
    fileUrl: null,
    fileName: null,
  });

  const [companyStampFile, setCompanyStampFile] = useState({
    fileUrl: null,
  });

  const [doneStampFile, setDoneStampFile] = useState({
    fileUrl: null,
  });

  const [paidStampFile, setPaidStampFile] = useState({
    fileUrl: null,
  });

  const [rejectedStampFile, setRejectedStampFile] = useState({
    fileUrl: null,
  });

  const [letterheadUrl, setLetterheadUrl] = useState({
    fileUrl: null,
  });

  const [cocExpireData, setCocExpireData] = useState(null);
  const [crExpireDate, setCrExpireDate] = useState(null);
  const [gaztExpireDate, setGaztExpireDate] = useState(null);
  const [gosiExpireDate, setGosiExpireDate] = useState(null);
  const [municipalityExpireDate, setMunicipalityExpireDate] = useState(null);
  const [vatExpireDate, setVatExpireDate] = useState(null);
  const [errorHold, setErrorHold] = useState(false);
  const [servicesDepartmentData, setServicesDepartmentData] = useState({});

  const [companyX, setCompanyX] = useState(null);
  const [companyY, setCompanyY] = useState(null);

  const [doneX, setDoneX] = useState(null);
  const [doneY, setDoneY] = useState(null);
  const [paidX, setPaidX] = useState(null);
  const [paidY, setPaidY] = useState(null);
  const [rejectedX, setRejectedX] = useState(null);
  const [rejectedY, setRejectedY] = useState(null);
  const [parentState, setParentState] = useState({
    x: null,
    y: null,
    width: null,
    height: null,
  });

  // for preview letterhead states
  const [letterheadPreview, setLetterheadPreview] = useState({
    letterheadPV: null,
    company_stampPV: null,
    done_stampPV: null,
    paid_stampPV: null,
    rejected_stampPV: null,
  });

  //letter head bg related
  const [letterPosition, setLetterPosition] = useState({ x: 0, y: 0 });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // avalibale services
  const [servicesData, setServicesData] = useState([]);

  let initialRequiredField = {
    general:{
      prefix:null,
      name:null,
      legal_name:null,
      min_avg_project_profit:null,
      choose_existing_employee:null,
    },
    available_services:{},
    locations:{
      company_locations:null,
    },
    styles:{
      color:null,
      text_color:null,
      logo:null,
      logo_dark:null,
    },
    letter_head_stamps:{
      letterheadbg:null,
      company_stamp:null,
      done_stamp:null,
      paid_stamp:null,
      rejected_stamp:null,
    },
  };
  let initialValues = {
    prefix: null,
    name: null,
    legal_name: null,
    min_avg_project_profit: null,
    color: null,
    text_color: null,
    logo: null,
    logo_dark: null,
    choose_existing_employee: false,
    company_locations: [],
    letterheadbg: null,
    company_stamp: null,
    done_stamp: null,
    paid_stamp: null,
    rejected_stamp: null,
  };
  servicesData.map((service) => {
    initialValues[service?.name] = null;
    initialRequiredField.available_services[service?.name] = null;
  });

  // Set page title
  useEffect(() => {
    document.title = pageTitle;
  }, [stateData]);

  useEffect(() => {
    getCompanyInfo();
  }, []);

  async function getCompanyInfo() {
    setIsLoaded(false);
    await axios
      .get("company/edit/" + companyId.companyId, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        let locationCountryData = response.data.data.company?.company_locations
          ? JSON.parse(response.data.data.company.company_locations)
          : "";
        let banckAccountData = response.data.data.company?.bank_accounts
          ? JSON.parse(response.data.data.company.bank_accounts)
          : "";
        let creditCardsData = response.data.data.company?.credit_cards
          ? JSON.parse(response.data.data.company.credit_cards)
          : "";
        let servicesDepartments = response.data.data.company?.departments
          ? JSON.parse(response.data.data.company.departments)
          : [];
        nationalAddressFile.fileUrl =
          response.data.data.company?.national_address_fileurl;
        nationalAddressFile.fileName =
          response.data.data.company?.national_address_filename;

        crNumberFile.fileUrl = response.data.data.company?.cr_number_fileurl;
        crNumberFile.fileName = response.data.data.company?.cr_number_filename;

        gosiFile.fileUrl = response.data.data.company?.gosi_fileurl;
        gosiFile.fileName = response.data.data.company?.gosi_filename;

        vatFile.fileUrl = response.data.data.company?.vat_fileurl;
        vatFile.fileName = response.data.data.company?.vat_filename;

        cocFile.fileUrl = response.data.data.company?.coc_fileurl;
        cocFile.fileName = response.data.data.company?.coc_filename;

        gaztFile.fileUrl = response.data.data.company?.gazt_fileurl;
        gaztFile.fileName = response.data.data.company?.gazt_filename;

        municipalityFile.fileUrl =
          response.data.data.company?.municipality_fileurl;
        municipalityFile.fileName =
          response.data.data.company?.municipality_filename;

        bankAccountFile.fileUrl =
          response.data.data.company?.bank_account_fileurl;
        bankAccountFile.fileName =
          response.data.data.company?.bank_account_filename;

        companyStampFile.fileUrl = response.data.data.company?.company_stamp;
        doneStampFile.fileUrl = response.data.data.company?.done_stamp;
        paidStampFile.fileUrl = response.data.data.company?.paid_stamp;
        rejectedStampFile.fileUrl = response.data.data.company?.rejected_stamp;
        letterheadUrl.fileUrl = response.data.data.company?.letter_head;
        parentState.x = response.data.data.company?.letter_head_x;
        parentState.y = response.data.data.company?.letter_head_y;
        parentState.width = response.data.data.company?.letter_head_w;
        parentState.height = response.data.data.company?.letter_head_h;

        letterheadPreview.letterheadPV = response.data.data?.letter_head;
        letterheadPreview.company_stampPV = response.data.data?.company_stamp;
        letterheadPreview.done_stampPV = response.data.data?.done_stamp;
        letterheadPreview.paid_stampPV = response.data.data?.paid_stamp;
        letterheadPreview.rejected_stampPV = response.data.data?.rejected_stamp;

        setCocExpireData(
          response.data.data.company?.coc_expire_date != null
            ? moment(response.data.data.company?.coc_expire_date).format(
                "YYYY/MM/DD"
              )
            : null
        );

        setCrExpireDate(
          response.data.data.company?.cr_expire_date != null
            ? moment(response.data.data.company?.cr_expire_date).format(
                "YYYY/MM/DD"
              )
            : null
        );
        setGaztExpireDate(
          response.data.data.company?.gazt_expire_date != null
            ? moment(response.data.data.company?.gazt_expire_date).format(
                "YYYY/MM/DD"
              )
            : null
        );
        setGosiExpireDate(
          response.data.data.company?.gosi_expire_date != null
            ? moment(response.data.data.company?.gosi_expire_date).format(
                "YYYY/MM/DD"
              )
            : null
        );
        setMunicipalityExpireDate(
          response.data.data.company?.municipality_expire_date != null
            ? moment(
                response.data.data.company?.municipality_expire_date
              ).format("YYYY/MM/DD")
            : null
        );
        setVatExpireDate(
          response.data.data.company?.vat_expire_date != null
            ? moment(response.data.data.company?.vat_expire_date).format(
                "YYYY/MM/DD"
              )
            : null
        );
        setCRNumberFile(crNumberFile);
        setGosiFile(gosiFile);
        setVatFile(vatFile);
        setCocFile(cocFile);
        setGaztFile(gaztFile);
        setMunicipalityFile(municipalityFile);
        setBankAccountFile(bankAccountFile);

        setCompanyStampFile(companyStampFile);
        setDoneStampFile(doneStampFile);
        setPaidStampFile(paidStampFile);
        setRejectedStampFile(rejectedStampFile);
        setLetterheadUrl(letterheadUrl);

        setLetterheadPreview(letterheadPreview);

        setCompanyX(response.data.data.company?.company_stamp_x);
        setCompanyY(response.data.data.company?.company_stamp_y);
        setDoneX(response.data.data.company?.done_stamp_x);
        setDoneY(response.data.data.company?.done_stamp_y);
        setPaidX(response.data.data.company?.paid_stamp_x);
        setPaidY(response.data.data.company?.paid_stamp_y);
        setRejectedX(response.data.data.company?.rejected_stamp_x);
        setRejectedY(response.data.data.company?.rejected_stamp_y);
        setParentState(parentState);

       // issue here for old companies below

        servicesDepartments?.map((service) => {
          let serviceName = service?.serviceName;
          let departmentId = service?.departmentId;
          servicesDepartmentData[serviceName] = {
            serviceName: serviceName,
            departmentId: departmentId,
          };
        });

        setServicesDepartmentData(servicesDepartmentData);

        setNationalAddressFile(nationalAddressFile);
        setCEOId(response.data.data.company?.ceo_id);
        setLocationData([...locationCountryData]);
        setBankAccountData([...banckAccountData]);
        setCreditCardsData([...creditCardsData]);
        setModel(response.data.data.company);
        setRecevingData(true);
        setTimeout(function () {
          setIsLoaded(true);
        }, 1000);
      })
      .catch(function (error) {
        toast.error(error);
      });

    await axios
      .get("/service/index")
      .then(function (response) {
        setServicesData(response.data?.data);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      });
  }

  useEffect(() => {
    // do something with the updated parent state here
  }, [parentState]);

  const stampPosition = {
    company_stamp_x: companyX,
    company_stamp_y: companyY,
    done_stamp_x: doneX,
    done_stamp_y: doneY,
    paid_stamp_x: paidX,
    paid_stamp_y: paidY,
    rejected_stamp_x: rejectedX,
    rejected_stamp_y: rejectedY,
    letter_head_x: parentState?.x,
    letter_head_y: parentState?.y,
    letter_head_w: parentState?.width,
    letter_head_h: parentState?.height,
  };

  function closeForm() {
    setAlertModalOpen(false);
    navigate("/companies");
  }

  function fileFormateSet(dateValue) {
    let ISOString = new Date(dateValue);
    const fiveMinutesInMillis = 720 * 60 * 1000;
    ISOString.setTime(ISOString.getTime() + fiveMinutesInMillis);
    return ISOString.toISOString();
  }
  // Send data
  const sendData = (values) =>
    new Promise((resolve) => {
      // setSendingData(false);
      // setStateData(true);
      debugger
      const formData = new FormData();

      if (
        values.choose_existing_employee.choose_manager &&
        values.choose_existing_employee.ceo_id
      ) {
        formData.append(
          "choose_manager",
          values.choose_existing_employee.choose_manager
        );
        formData.append("ceo_id", values.choose_existing_employee.ceo_id);
      } else {
        formData.append("ceo_name", values.choose_existing_employee.ceo_name);
        formData.append("ceo_email", values.choose_existing_employee.ceo_email);
      }
      if (values.logo) {
        formData.append(
          "logo_url",
          values.logo.fileUrl ? values.logo.fileUrl : values.logo
        );
      }
      if (values.logo_dark) {
        formData.append(
          "logo_dark_url",
          values.logo_dark.fileUrl ? values.logo_dark.fileUrl : values.logo_dark
        );
      }
      if (values.favicon) {
        formData.append(
          "favicon_url",
          values.favicon.fileUrl ? values.favicon.fileUrl : values.favicon
        );
      }
      if (values.prefix) {
        formData.append("prefix", values.prefix);
      }
      if (values.name) {
        formData.append("name", values.name);
      }
      if (values.legal_name) {
        formData.append("legal_name", values.legal_name);
      }
      if (values.country) {
        formData.append("country", values.country);
      }
      if (values.city) {
        formData.append("city", values.city);
      }
      if (values.address) {
        formData.append("address", values.address);
      }
      if (values.color) {
        formData.append("color", values.color);
      }
      if (values.text_color) {
        formData.append("text_color", values.text_color);
      }
      if (values.signature_website_text) {
        formData.append(
          "signature_website_text",
          values.signature_website_text
        );
      }
      if (values.signature_website_url) {
        formData.append("signature_website_url", values.signature_website_url);
      }
      if (values.phone) {
        formData.append("phone", values.phone);
      }
      if (values.extention) {
        formData.append("extention", values.extention);
      }
      if (values.signature_facebook_url) {
        formData.append(
          "signature_facebook_url",
          values.signature_facebook_url
        );
      }
      if (values.signature_instagram_url) {
        formData.append(
          "signature_instagram_url",
          values.signature_instagram_url
        );
      }
      if (values.signature_linkedin_url) {
        formData.append(
          "signature_linkedin_url",
          values.signature_linkedin_url
        );
      }
      if (values.signature_twitter_url) {
        formData.append("signature_twitter_url", values.signature_twitter_url);
      }

      if (values.finance_department_id) {
        formData.append("finance_department_id", values.finance_department_id);
      }
      if (values.hr_department_id) {
        formData.append("hr_department_id", values.hr_department_id);
      }
      if (values.inventory_department_id) {
        formData.append(
          "inventory_department_id",
          values.inventory_department_id
        );
      }

      if (values.national_address_file) {
        formData.append(
          "national_address_fileurl",
          values.national_address_file.fileUrl
        );
        formData.append(
          "national_address_filename",
          values.national_address_file.fileName
        );
      }
      if (values.min_avg_project_profit) {
        formData.append(
          "min_avg_project_profit",
          values.min_avg_project_profit
        );
      }
      if (values.cr_number) {
        formData.append("cr_number", values.cr_number);
      }
      if (values.cr_expire_date) {
        let dateFormateGet = fileFormateSet(values.cr_expire_date);

        formData.append("cr_expire_date", dateFormateGet);
      }
      if (values.cr_number_file) {
        formData.append("cr_number_fileurl", values.cr_number_file.fileUrl);
        formData.append("cr_number_filename", values.cr_number_file.fileName);
      }
      if (values.gosi_number) {
        formData.append("gosi_number", values.gosi_number);
      }
      if (values.gosi_expire_date) {
        let dateFormateGet = fileFormateSet(values.gosi_expire_date);

        formData.append("gosi_expire_date", dateFormateGet);
      }
      if (values.gosi_file) {
        formData.append("gosi_fileurl", values.gosi_file.fileUrl);
        formData.append("gosi_filename", values.gosi_file.fileName);
      }
      if (values.vat_number) {
        formData.append("vat_number", values.vat_number);
      }
      if (values.vat_file) {
        formData.append("vat_fileurl", values.vat_file.fileUrl);
        formData.append("vat_filename", values.vat_file.fileName);
      }
      if (values.coc_number) {
        formData.append("coc_number", values.coc_number);
      }
      if (values.coc_expire_date) {
        let dateFormateGet = fileFormateSet(values.coc_expire_date);
        formData.append("coc_expire_date", dateFormateGet);
      }
      if (values.coc_file) {
        formData.append("coc_fileurl", values.coc_file.fileUrl);
        formData.append("coc_filename", values.coc_file.fileName);
      }
      if (values.gazt_number) {
        formData.append("gazt_number", values.gazt_number);
      }
      if (values.gazt_expire_date) {
        let dateFormateGet = fileFormateSet(values.gazt_expire_date);

        formData.append("gazt_expire_date", dateFormateGet);
      }
      if (values.gazt_file) {
        formData.append("gazt_fileurl", values.gazt_file.fileUrl);
        formData.append("gazt_filename", values.gazt_file.fileName);
      }
      if (values.municipality_number) {
        formData.append("municipality_number", values.municipality_number);
      }
      if (values.municipality_expire_date) {
        let dateFormateGet = fileFormateSet(values.municipality_expire_date);

        formData.append("municipality_expire_date", dateFormateGet);
      }
      if (values.municipality_file) {
        formData.append(
          "municipality_fileurl",
          values.municipality_file.fileUrl
        );
        formData.append(
          "municipality_filename",
          values.municipality_file.fileName
        );
      }
      if (values.bank_account_file) {
        formData.append(
          "bank_account_fileurl",
          values.bank_account_file.fileUrl
        );
        formData.append(
          "bank_account_filename",
          values.bank_account_file.fileName
        );
      }
      if (values.bank_accounts) {
        formData.append("bank_accounts", JSON.stringify(values.bank_accounts));
      }
      if (values.credit_cards) {
        formData.append("credit_cards", JSON.stringify(values.credit_cards));
      }
      if (values.company_locations) {
        formData.append(
          "company_locations",
          JSON.stringify(values.company_locations)
        );
      }

      let result = [];
      servicesData.map((service, key) => {
        let departmentName = service.name;
        if (values[departmentName] != null) {
          result[key] = values[departmentName];
        }
      });
      formData.append("departments", JSON.stringify(result));

      if (values.company_stamp) {
        formData.append("company_stamp_url", values.company_stamp.fileUrl);
      }

      if (values.done_stamp) {
        formData.append("done_stamp_url", values.done_stamp.fileUrl);
      }
      if (values.paid_stamp) {
        formData.append("paid_stamp_url", values.paid_stamp.fileUrl);
      }
      if (values.rejected_stamp) {
        formData.append("rejected_stamp_url", values.rejected_stamp.fileUrl);
      }

      if (values.letterheadbg) {
        formData.append("letter_head_url", values.letterheadbg.fileUrl);
      }

      if (values.company_stamp) {
        formData.append("stamp_positions", JSON.stringify(stampPosition));
        formData.append("letter_head_full_width", width);
        formData.append("letter_head_full_height", height);
      }

      axios
        .post("/update/companies/" + companyId.companyId, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          // setSendingData(false);
          // resolve()
          toast.success("Record update successfully! ");
          setTimeout(function () {
            navigate("/companies");
          }, 1000);
        })
        .catch(function (error) {
          setSendingData(false);
          setErrorHold(false);
          toast.error(error.response.data.message);
          resolve();
        });
    });

  // Submit form
  const onSubmit = async (values) => {
    await sendData(values);
  };

  if (error) {
    return <Error error={error} />;
  }
  if (!isLoaded) {
    return <Loader fullscreen={false} />;
  }

  return (
    <div className="h-full">
      {/* Page title & actions */}
      <NavBar pageTitle={pageTitle}>
        <a
          href="#"
          onClick={() => setAlertModalOpen(true)}
          disabled={sendingData}
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 mr-2 disabled:opacity-75"
        >
          Back
        </a>
        {/* <button
          disabled={false}
          type="button"
          onClick={() => document.getElementById("draftmainFormSubmit").click()}
          className="mr-2 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-0 disabled:opacity-75"
        >
          {sendingData ? "Submitting.." : "Save and Continue Later"}
        </button> */}
        <button
          disabled={stateData}
          type="submit"
          onClick={() => document.getElementById("mainFormSubmit").click()}
          className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75"
        >
          {sendingData ? "Submitting.." : "Save"}
        </button>
      </NavBar>
      {/* /Page title & actions */}

      <main className="">
        <div className="max-w-3xl mx-auto px-0 sm:px-6  md:space-x-5 lg:max-w-full lg:px-8 mt-0 relative pb-0 lg:pb-6">
          {sendingData && <Loader fullscreen={false} />}
         
          {recevingData && (
            <UpdateCrudForm
              onSubmit={onSubmit}
              initialValues={{
                ...model,
                ...servicesDepartmentData,
                bank_accounts: [...bankAccountData],
                company_locations: [...locationData],
                credit_cards: [...creditCardsData],
                national_address_file: nationalAddressFile,
                cr_number_file: crNumberFile,
                gosi_file: gosiFile,
                vat_file: vatFile,
                coc_file: cocFile,
                gazt_file: gaztFile,
                municipality_file: municipalityFile,
                bank_account_file: bankAccountFile,
                coc_expire_date: cocExpireData,
                cr_expire_date: crExpireDate,
                gazt_expire_date: gaztExpireDate,
                gosi_expire_date: gosiExpireDate,
                municipality_expire_date: municipalityExpireDate,
                vat_expire_date: vatExpireDate,
                company_stamp: companyStampFile,
                done_stamp: doneStampFile,
                paid_stamp: paidStampFile,
                rejected_stamp: rejectedStampFile,
                letterheadbg: letterheadUrl,
              }}
              setButtonActive={initialValues}
              savaButtonAction={(val) => setStateData(val)}
              companyLocationData={locationData}
              getCEOId={CEOId}
              sendError={errorHold}
              draftId={null}
              user={user}
              companyX={companyX}
              companyY={companyY}
              setCompanyX={setCompanyX}
              setCompanyY={setCompanyY}
              doneX={doneX}
              doneY={doneY}
              setDoneX={setDoneX}
              setDoneY={setDoneY}
              paidX={paidX}
              paidY={paidY}
              setPaidX={setPaidX}
              setPaidY={setPaidY}
              rejectedX={rejectedX}
              rejectedY={rejectedY}
              setRejectedX={setRejectedX}
              setRejectedY={setRejectedY}
              letterPosition={letterPosition}
              setLetterPosition={setLetterPosition}
              width={width}
              setWidth={setWidth}
              height={height}
              setHeight={setHeight}
              servicesData={servicesData}
              onUpdate={setParentState}
              parentState={parentState}
              letterheadPV={letterheadPreview.letterheadPV}
              company_stampPV={letterheadPreview.company_stampPV}
              done_stampPV={letterheadPreview.done_stampPV}
              paid_stampPV={letterheadPreview.paid_stampPV}
              rejected_stampPV={letterheadPreview.rejected_stampPV}
              setIsLoaded={(val) => setIsLoaded(val)}
              tabSelect = {tabSelect}
              initialRequiredField={initialRequiredField}

              // companyInfo={companyInfo}
            />
          )} 
        </div>
      </main>

      <AlertModal
        title="Close Form"
        confirm={closeForm}
        close={() => setAlertModalOpen(false)}
        open={alertModalOpen}
      />

      <ToastContainer theme="colored" />
    </div>
  );
}
